import { Component, OnInit } from "@angular/core";
import { ApiService } from "@services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import {
  ColdStorageDeliveryDetails
} from "@components/aftc-digitalization/aftc-digitalization-models/coldStorageDeliveryDetails";
import { ToastrService } from "ngx-toastr";


@Component({
  selector: "app-solar-tunnel-dryer-delivery-print",
  templateUrl: "./solar-tunnel-dryer-delivery-print.component.html",
  styleUrls: ["./solar-tunnel-dryer-delivery-print.component.scss"]
})
export class SolarTunnelDryerDeliveryPrintComponent implements OnInit {

  getDetails: ColdStorageDeliveryDetails;
  destroy$: Subject<boolean> = new Subject<boolean>();
  currentDate: string;

  constructor(private apiServices: ApiService, private spinner: NgxSpinnerService,
              private router: Router, private datePipe: DatePipe, private toaster: ToastrService) {
    this.currentDate = this.datePipe.transform(new Date(), "dd/MM/yyyy");
  }

  ngOnInit() {
    const deliveryId = Number(sessionStorage.getItem("deliveryId"));
    this.getDeliveryDetails(deliveryId);
  }

  ngOnDestroy() {
    sessionStorage.removeItem("deliveryId");
  }

  getDeliveryDetails(id: number) {
    this.apiServices.getColdStorageDeliveryDetailsById(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.getDetails = data;
        setTimeout(() => {
          this.print();
        }, 3000);
      }, (err: any) => {
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  print() {
    window.print();
    setTimeout(() => {
      this.router.navigate(["/main/frontOffice"]);
    });
  }

}
