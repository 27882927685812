import { TollGate } from "@components/aftc-digitalization/aftc-digitalization-models/TollGate";
import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ApiService } from "@services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, takeUntil } from "rxjs";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";


@Component({
  selector: "app-packing-deposit-entry-form",
  templateUrl: "./packing-deposit-component.html",
  styleUrls: ["./packing-deposit-component.scss"]
})
export class PackingDepositComponent implements OnInit {
  getPassDetails: TollGate | undefined;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private apiServices: ApiService, private spinner: NgxSpinnerService,
              private router: Router, private toaster: ToastrService) {

  }

  depositForm = new UntypedFormGroup({
    stockDepositId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    depositApplicationNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    depositDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    depositorName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    declarationDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    invoiceNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    billDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    endDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    commodity: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    noOfBags: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    weight: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    typeOfVehicle: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    wheels: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehicleNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    amount: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    createdAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    updatedAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    createdBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    updatedBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    currentStage: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    irmReceiptNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    storageType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    serviceType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    billPeriodType: new UntypedFormControl("", [Validators.required, Validators.nullValidator])
  });

  depositFormErrors = {
    depositApplicationNo: "",
    depositorName: "",
    invoiceNo: "",
    iRMReceiptNo: "",
    depositDate: "",
    commodity: "",
    noOfBags: "",
    weight: "",
    typeOfVehicle: "",
    wheels: "",
    vehicleNo: "",
    amount: "",
    billPeriodType: "",
    declarationDate: "",
  };

  formValidation(from: any) {
    this.depositFormErrors.depositApplicationNo = "";
    this.depositFormErrors.depositorName = "";
    this.depositFormErrors.invoiceNo = "";
    this.depositFormErrors.iRMReceiptNo = "";
    this.depositFormErrors.depositDate = "";
    this.depositFormErrors.declarationDate = "";
    this.depositFormErrors.commodity = "";
    this.depositFormErrors.noOfBags = "";
    this.depositFormErrors.weight = "";
    this.depositFormErrors.typeOfVehicle = "";
    this.depositFormErrors.wheels = "";
    this.depositFormErrors.vehicleNo = "";
    this.depositFormErrors.amount = "";
    this.depositFormErrors.billPeriodType = "";
    let hasError = false;

    if (this.depositForm.get("depositApplicationNo").invalid) {
      this.depositFormErrors.depositApplicationNo = "*Deposit Application No is Required";
      hasError = true;
    }
    if (this.depositForm.get("depositorName").invalid) {
      this.depositFormErrors.depositorName = "*Depositor Name is Required";
      hasError = true;
    }
    if (this.depositForm.get("invoiceNo").invalid) {
      this.depositFormErrors.invoiceNo = "*Invoice Number is Required";
      hasError = true;
    }
    if (this.depositForm.get("irmReceiptNo").invalid) {
      this.depositFormErrors.iRMReceiptNo = "*IRM Receipt Number is Required";
      hasError = true;
    }
    if (this.depositForm.get("depositDate").invalid) {
      this.depositFormErrors.depositDate = "*Deposit Date is Required";
      hasError = true;
    }
    if (this.depositForm.get("declarationDate").invalid) {
      this.depositFormErrors.declarationDate = "*Declaration Date is Required";
      hasError = true;
    }
    if (this.depositForm.get("commodity").invalid) {
      this.depositFormErrors.commodity = "*Commodity is Required";
      hasError = true;
    }
    if (this.depositForm.get("noOfBags").invalid || this.depositForm.get("noOfBags").value == 0) {
      this.depositFormErrors.noOfBags = "*No of Bags is Required";
      hasError = true;
    }
    if (this.depositForm.get("weight").invalid || this.depositForm.get("weight").value == 0) {
      this.depositFormErrors.weight = "*Weight is Required";
      hasError = true;
    }
    if (this.depositForm.get("typeOfVehicle").invalid) {
      this.depositFormErrors.typeOfVehicle = "*Type of Vehicle is Required";
      hasError = true;
    }
    if (this.depositForm.get("wheels").invalid) {
      this.depositFormErrors.wheels = "*Wheels Count is Required";
      hasError = true;
    }
    if (this.depositForm.get("vehicleNo").invalid) {
      this.depositFormErrors.vehicleNo = "*Vehicle Number is Required";
      hasError = true;
    }
    if (this.depositForm.get("amount").invalid) {
      this.depositFormErrors.amount = "*Amount is Required";
      hasError = true;
    }
    if (this.depositForm.get("billPeriodType").invalid) {
      this.depositFormErrors.billPeriodType = "*Bill Period Type is Required";
      hasError = true;
    }

    if (!hasError) {
      this.addStockDeposit();
    }
  }

  ngOnInit() {
    this.getApplicationNo();
    this.getTollgateDetailsForPackingDeposit();
    this.initial_data();
  }

  ngOnDestroy() {
    sessionStorage.removeItem("storageType");
    sessionStorage.removeItem("serviceType");
    sessionStorage.removeItem("tollgateSeqNo");
  }

  getApplicationNo() {
    this.apiServices.getPackingDepositAppNo()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.depositForm.controls["depositApplicationNo"].setValue(data.depositAppNo);
      }, (err: any) => {
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  initial_data() {
    const now = new Date();
    const localDateTime = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
    const formattedDateTime = localDateTime.toISOString().slice(0, 16);
    this.depositForm.controls["depositDate"].setValue(formattedDateTime);
  }

  getTollgateDetailsForPackingDeposit() {
    this.apiServices.getTollGateById(Number(sessionStorage.getItem('tollgateSeqNo')))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.getPassDetails = data;
        this.depositForm.controls["depositorName"].setValue(this.getPassDetails.memberName);
        this.depositForm.controls["commodity"].setValue(this.getPassDetails.commodityName);
        this.depositForm.controls["noOfBags"].setValue(this.getPassDetails.bagsUnits);
        this.depositForm.controls["weight"].setValue(this.getPassDetails.weightMt);
        this.depositForm.controls["typeOfVehicle"].setValue(this.getPassDetails.tollgateVehicleDetails.vehType);
        this.depositForm.controls["wheels"].setValue(this.getPassDetails.tollgateVehicleDetails.wheelCount);
        this.depositForm.controls["vehicleNo"].setValue(this.getPassDetails.tollgateVehicleDetails.vehNumberPlate);
        this.depositForm.controls["amount"].setValue(this.getPassDetails.charges);
        this.depositForm.controls["irmReceiptNo"].setValue(this.getPassDetails.irmNo);
      }, (err: any) => {
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  addStockDeposit() {
    this.spinner.show();
    const entryDate = this.depositForm.get("depositDate").value;
    const epochTimestamp = new Date(entryDate).getTime() / 1000;
    this.depositForm.controls["depositDate"].setValue(epochTimestamp);
    const entryDeclarationDate = this.depositForm.get("declarationDate").value;
    const epochTimes = new Date(entryDeclarationDate).getTime() / 1000;
    this.depositForm.controls["declarationDate"].setValue(epochTimes);
    this.depositForm.controls["billDate"].setValue(epochTimes);
    this.depositForm.controls["createdAt"].setValue(Math.floor(new Date().getTime() / 1000));
    this.depositForm.controls["storageType"].setValue(sessionStorage.getItem("storageType"));
    this.depositForm.controls["serviceType"].setValue(sessionStorage.getItem("serviceType"));
    this.apiServices.addPackingStockDeposit(this.depositForm.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
        this.saveAlert(data.stockDepositId);
      }, (err: any) => {
        this.spinner.hide();
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  saveAlert(depositId: number) {
    Swal.fire({
      title: "Details Saved Successfully...",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate__animated animate__fadeInDown"
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp"
      }
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.setItem("depositorId", String(depositId));
        this.router.navigate(["/packing-deposit-receipt"]);
      }
    });
  }

}
