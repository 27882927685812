import { TollGate } from "@components/aftc-digitalization/aftc-digitalization-models/TollGate";
import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ApiService } from "@services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, takeUntil } from "rxjs";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { CommodityDetails } from "@/model/commodityDetails/CommodityDetails";
import { ToastrService } from "ngx-toastr";


@Component({
  selector: "app-warehouse-delivery-entry-form",
  templateUrl: "./warehouse-delivery-entry-form.component.html",
  styleUrls: ["./warehouse-delivery-entry-form.component.scss"]
})
export class WarehouseDeliveryEntryFormComponent implements OnInit {
  getLocalPassDetails: TollGate[] = [];
  commodityDetails: CommodityDetails[] = [];
  commodityNames: string[] = [];
  getPassDetails: TollGate | undefined;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private apiServices: ApiService, private spinner: NgxSpinnerService,
              private router: Router, private toaster: ToastrService) {
  }

  deliveryForm = new UntypedFormGroup({
    stockDeliveryId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    deliverOrderNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    deliveryDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    commodityName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    bagsOf: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    weighing: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    warehouseReceiptNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    date: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehicleNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    storageChargesInvoiceNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    atOfficeDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    amount: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    depositorName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    irmReceiptNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    currentStage: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    createdAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    createdBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    updatedAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    storageType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    serviceType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    billPeriodType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    godownNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    stackNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator])
  });

  deliveryFormErrors = {
    deliverOrderNo: "",
    commodity: "",
    warehouseReceiptNo: "",
    storageChargesInvoiceNo: "",
    deliveryDate: "",
    bagsOf: "",
    weighing: "",
    date: "",
    depositorName: "",
    iRMReceiptNo: "",
    vehicleNo: "",
    atOfficeDate: "",
    amount: "",
    billPeriodType: "",
    // godownNo: "",
    // stackNo: ""
  };

  ngOnInit() {
    if (sessionStorage.getItem("storageType") == "Common Warehouse") {
      this.getCWApplicationNo();
    } else if (sessionStorage.getItem("storageType") == "SLCM") {
      this.getSLCMApplicationNo();
    }
    this.getTollgateDetailsForWarehouseDelivery();
    this.initial_data();
    this.getCommodityDetails();
  }

  ngOnDestroy() {
    sessionStorage.removeItem("storageType");
    sessionStorage.removeItem("serviceType");
    sessionStorage.removeItem("tollgateSeqNo");
  }

  getCWApplicationNo() {
    this.apiServices.getDeliveryAppNo()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.deliveryForm.controls["deliverOrderNo"].setValue(data.depositAppNo);
      }, (err: any) => {
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  getSLCMApplicationNo() {
    this.apiServices.getSLCMDeliveryAppNo()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.deliveryForm.controls["deliverOrderNo"].setValue(data.depositAppNo);
      }, (err: any) => {
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  getCommodityDetails() {
    this.apiServices.getCommodityDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.commodityDetails = data;
        const set = new Set(this.commodityDetails.map(item => item.commodityName));
        this.commodityNames = Array.from(set).sort((a, b) => a.localeCompare(b));
      }, (err: any) => {
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  initial_data() {
    const now = new Date();
    const localDateTime = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
    const formattedDateTime = localDateTime.toISOString().slice(0, 16);
    this.deliveryForm.controls["deliveryDate"].setValue(formattedDateTime);
  }

  formValidation(deliveryForm: any) {
    this.deliveryFormErrors.deliverOrderNo = "";
    this.deliveryFormErrors.commodity = "";
    this.deliveryFormErrors.warehouseReceiptNo = "";
    this.deliveryFormErrors.billPeriodType = "";
    this.deliveryFormErrors.date = "";
    // this.deliveryFormErrors.godownNo = "";
    // this.deliveryFormErrors.stackNo = "";
    let hasError = false;

    if (this.deliveryForm.get("deliverOrderNo").invalid) {
      this.deliveryFormErrors.deliverOrderNo = "*Delivery order no is required";
      hasError = true;
    }
    if (this.deliveryForm.get("commodityName").invalid) {
      this.deliveryFormErrors.commodity = "*Commodity name is required";
      hasError = true;
    }
    if (this.deliveryForm.get("warehouseReceiptNo").invalid) {
      this.deliveryFormErrors.warehouseReceiptNo = "*Warehouse receipt no is required";
      hasError = true;
    }
    if (this.deliveryForm.get("date").invalid) {
      this.deliveryFormErrors.date = "*Date is required";
      hasError = true;
    }
    // if (this.deliveryForm.get("godownNo").invalid) {
    //   this.deliveryFormErrors.godownNo = "*Godown no is required";
    //   hasError = true;
    // }
    // if (this.deliveryForm.get("stackNo").invalid) {
    //   this.deliveryFormErrors.stackNo = "*Stack no is required";
    //   hasError = true;
    // }
    if (!hasError) {
      this.addStockDelivery();
    }
  }

  getTollgateDetailsForWarehouseDelivery() {
    this.apiServices.getTollGateById(Number(sessionStorage.getItem('tollgateSeqNo')))
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.getPassDetails = data;
        this.deliveryForm.controls["bagsOf"].setValue(this.getPassDetails.bagsUnits);
        this.deliveryForm.controls["weighing"].setValue(this.getPassDetails.weightMt);
        this.deliveryForm.controls["depositorName"].setValue(this.getPassDetails.memberName);
        this.deliveryForm.controls["vehicleNo"].setValue(this.getPassDetails.tollgateVehicleDetails.vehNumberPlate);
        this.deliveryForm.controls["commodityName"].setValue(this.getPassDetails.commodityName);
        this.deliveryForm.controls["irmReceiptNo"].setValue(this.getPassDetails.irmNo);
      }, (err: any) => {
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  addStockDelivery() {
    this.spinner.show();
    const deliveryDate = this.deliveryForm.get("deliveryDate").value;
    const epochTimestamp = new Date(deliveryDate).getTime() / 1000;
    this.deliveryForm.controls["deliveryDate"].setValue(epochTimestamp);
    const date = this.deliveryForm.get("date").value;
    const epochStartTimestamp = new Date(date).getTime() / 1000;
    this.deliveryForm.controls["date"].setValue(epochStartTimestamp);
    const atOfficeDate = this.deliveryForm.get("atOfficeDate").value;
    const epochEndTimestamp = new Date(atOfficeDate).getTime() / 1000;
    this.deliveryForm.controls["atOfficeDate"].setValue(epochEndTimestamp);
    this.deliveryForm.controls["createdAt"].setValue(Math.floor(new Date().getTime() / 1000));
    this.deliveryForm.controls["storageType"].setValue(sessionStorage.getItem("storageType"));
    this.deliveryForm.controls["serviceType"].setValue(sessionStorage.getItem("serviceType"));
    this.apiServices.addStockDelivery(this.deliveryForm.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
        this.saveAlert(data.stockDeliveryId);
      }, (err: any) => {
        this.spinner.hide();
        this.toaster.error("Something went wrong, Please try again later");
      });
  }

  saveAlert(deliveryId: number) {
    Swal.fire({
      title: "Details Saved Successfully...",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate__animated animate__fadeInDown"
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp"
      }
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.setItem("deliveryId", String(deliveryId));
        this.router.navigate(["/deliveryPrint"]);
      }
    });
  }

}
