<div class="col-md-12">
  <h3 class="ml-2">Solar Tunnel Dryer Deposit Entry</h3>
</div>
<div class="container-fluid">
  <form [formGroup]="depositForm">
    <div class="row mt-2">
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control textCap" placeholder="Deposit Application No" disabled
            formControlName="depositApplicationNo">
          <label>Deposit Application No<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.depositApplicationNo"
          class="text-danger">{{depositFormErrors.depositApplicationNo}}</span>
      </div>
      <!--<div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="datetime-local" class="form-control" placeholder="Date Time" formControlName="depositDate">
          <label class="">Date Time<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.depositDate" class="text-danger">{{depositFormErrors.depositDate}}</span>
      </div>-->
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Depositor Name" formControlName="depositorName">
          <label class="">Depositor Name<span style="color:red;">*</span></label>
        </div>
        <!-- <ng-multiselect-dropdown class=""
          [placeholder]="'Select an option'"
          [settings]="dropdownSettings"
          [data]="dropdownList"
          [(ngModel)]="selectedItem"
          formControlName="depositorName">
        </ng-multiselect-dropdown> -->
        <span *ngIf="depositFormErrors.depositorName" class="text-danger">{{depositFormErrors.depositorName}}</span>
      </div>
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Invoice No" formControlName="invoiceNo">
          <label class="">Invoice No<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.invoiceNo" class="text-danger">{{depositFormErrors.invoiceNo}}</span>
      </div>
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="date" class="form-control textCap" placeholder="Declaration Date"
            formControlName="declarationDate">
          <label>Declaration Date<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.declarationDate" class="text-danger">{{depositFormErrors.declarationDate}}</span>
      </div>

      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control textCap" placeholder="Supplier Name" formControlName="supplierName">
          <label>Supplier Name<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.supplierName" class="text-danger">{{depositFormErrors.supplierName}}</span>
      </div>

      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control textCap" placeholder="Commodity" formControlName="commodity">
          <label>Commodity<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.commodity" class="text-danger">{{depositFormErrors.commodity}}</span>
      </div>
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="No.of Bags" formControlName="noOfBags">
          <label class="">No.of Bags<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.noOfBags" class="text-danger">{{depositFormErrors.noOfBags}}</span>
      </div>
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Weight" formControlName="weight">
          <label class="">Weight<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.weight" class="text-danger">{{depositFormErrors.weight}}</span>
      </div>
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Type Of Vehicle" formControlName="typeOfVehicle">
          <label class="">Type Of Vehicle<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.typeOfVehicle" class="text-danger">{{depositFormErrors.typeOfVehicle}}</span>
      </div>

      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Wheels" formControlName="wheels">
          <label>Wheels<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.wheels" class="text-danger">{{depositFormErrors.wheels}}</span>
      </div>
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Vehicle No" formControlName="vehicleNo">
          <label class="">Vehicle No<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.vehicleNo" class="text-danger">{{depositFormErrors.vehicleNo}}</span>
      </div>
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="IRM Receipt No" formControlName="irmReceiptNo">
          <label class="">IRM Receipt No<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.iRMReceiptNo" class="text-danger">{{depositFormErrors.iRMReceiptNo}}</span>
      </div>
      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <input type="text" class="form-control" placeholder="Amount" formControlName="amount">
          <label>Amount<span style="color:red;">*</span></label>
        </div>
        <span *ngIf="depositFormErrors.amount" class="text-danger">{{depositFormErrors.amount}}</span>
      </div>

      <div class="col-lg-3  col-md-3 mt-2">
        <div class="form-floating">
          <select type="text" class="form-select" formControlName="billPeriodType">
            <option disabled selected hidden value="">Select bill period</option>
            <option value="Day wise">Day wise</option>
            <option value="Month wise">Month wise</option>
          </select>
          <label>Bill Period Type<span style="color:red;">*</span></label>
          <span *ngIf="depositFormErrors.billPeriodType" class="text-danger">{{depositFormErrors.billPeriodType}}</span>
        </div>
      </div>
    </div>

    <div class="row justify-content-center mt-4 mb-5 ml-2 mr-2">
      <button type="submit" class="btn btn-success col-md-1" (click)="formValidation(depositForm)">Save</button>
    </div>
  </form>
</div>
<ngx-spinner bdColor="rgba(255, 255, 255, 1)" size="large" color="#FFFFFF" [fullScreen]="true">
  <div class="loader"></div>
</ngx-spinner>