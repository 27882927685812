<div class="container" style="border: 2px solid grey;">
  <div class="row">
    <span>GSTIN: <b>33AABCT1101F1Z7</b></span>
  </div>
  <div class="row mt-1">
    <div class="col-2 ml-4">
      <img src="../../../../assets/img/AFTC%20logo.png" style="height: 150px; width: 150px">
    </div>
    <div class="col-9">
      <div class="mt-4"></div>
      <p class="text1">AGRO FOOD TRADE CENTRE</p>
      <p class="text5">Sikkandarchavadi, Alanganallur Road, Madurai - 625 018. <i class="fas fa-phone-alt ml-2"></i>
        0452-2660669</p>
      <h3 class="text-center"><b class="underline">DEPOSIT APPLICATION - PACKING CENTRE</b></h3>
    </div>
  </div>

  <div class="row">
    <div class="col-6 mt-2">
      <div class="d-flex justify-content-start">
        <span class="content1">Deposit Application No: <b>{{depositorDetails?.depositApplicationNo}}</b></span>
      </div>
    </div>
    <div class="col-5">
      <div class="d-flex justify-content-end">
        <span class="content1">Date: <b>{{currentDate}}</b></span>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="row">
      <span class="content3 col-12"> Please accept the following commodity of our concern namely</span>
    </div>
    <div class="row">
      <div class="col-1">
        <span class="label-content">M/s</span>
      </div>
      <div class="col-4 underline text-center">
        <label class="label-content name-wrap">{{depositorDetails?.depositorName}}</label>
      </div>
      <div class="col-5">
        <span class="label-content">vide Invoice No/Self declaration</span>
      </div>
      <div class="col-2 underline text-center">
        <label class="label-content">{{depositorDetails?.invoiceNo}}</label>
      </div>
    </div>
    <div class="row">
      <div class="col-2">
        <span class="label-content">dt&nbsp;</span>
        <label class="label-content underline">{{depositorDetails?.billDate * 1000 | date: 'dd/MM/yyyy'}}</label>
      </div>
      <div class="col-4">
        <span class="label-content">for Packing.</span>
      </div>
      <div class="col-2">
        <span class="label-content">Commodity</span>
      </div>
      <div class="col-3 underline text-center">
        <label class="label-content">{{depositorDetails?.commodity}}</label>
      </div>
    </div>

    <div class="row">
      <div class="col-2">
        <span class="label-content">No. of Bags</span>
      </div>
      <div class="col-2 underline text-center">
        <label class="label-content">{{depositorDetails?.noOfBags}}</label>
      </div>
      <div class="col-3"></div>
      <div class="col-1">
        <span class="label-content">Weight</span>
      </div>
      <div class="col-2 underline text-center">
        <label class="label-content">{{depositorDetails?.weight}}</label>
      </div>
      <div class="col-2">
        <span class="label-content">(Kgs/M.Tons)</span>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <span class="label-content">Type of Vehicle</span>
      </div>
      <div class="col-2 underline text-center">
        <label class="label-content">{{depositorDetails?.typeOfVehicle}}</label>
      </div>
      <div class="col-1">
        <span class="label-content">Wheels</span>
      </div>
      <div class="col-1 underline text-center">
        <label class="label-content">{{depositorDetails?.wheels}}</label>
      </div>
      <div class="col-2">
        <span class="label-content">Vehicle No</span>
      </div>
      <div class="col-3 underline text-center">
        <label class="label-content">{{depositorDetails?.vehicleNo}}</label>
      </div>
    </div>

    <div class="row">
      <div class="col-3">
        <span class="label-content">Toll Gate Receipt No</span>
      </div>
      <div class="col-4 underline text-center">
        <label class="label-content">{{depositorDetails?.irmReceiptNo}}</label>
      </div>
      <div class="col-3">
        <span class="label-content">and Amount Rs.</span>
      </div>
      <div class="col-2 underline text-center">
        <label class="label-content">{{depositorDetails?.amount | currency: 'INR'}}</label>
      </div>
    </div>
  </div>
  <div class="row mt-4" style="margin-top: 70px !important;">
    <label class="content1 text-start col-4" style="align-self: end">Manager</label>
    <label class="content1 text-center col-4" style="align-self: end">Accountant</label>
    <label class="content1 text-end col-4">Signature of the Depositor/<br>Authorized Representative</label>
  </div>

  <div class="row mt-2">
    <div class="col-6">
      <div class="d-flex justify-content-start">
        <label class="content1"> 2. Quality Report: Accepted/not accepted</label>
      </div>
    </div>
  </div>

  <div class="row mt-3" style="margin-top: 70px !important;">
    <div class="col-5 ml-3">
      <div class="d-flex justify-content-start">
        <label class="content1">Operator</label>
      </div>
    </div>
    <div class="col-6">
      <div class="d-flex justify-content-end">
        <label class="content1">Quality Control Manager</label>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <label class="label-content">3. Packing details:</label>
    <div class="col-6 ml-3">
      <span class="label-content">Received the said commodity for packing in</span>
    </div>
    <div class="col-1 underline">
      <label class="">&nbsp;</label>
    </div>
    <div class="col-4">
      <span class="label-content">(gms/kg) packets and entered</span>
    </div>
  </div>

  <div class="row">
    <div class="col-6 ml-3">
      <span class="label-content">in Receipt & Delivery Register Page No </span>
    </div>
    <div class="col-2 underline">
      <label class="label-content"></label>
    </div>
  </div>

  <div class="row mt-4" style="margin-top: 30px !important;">
    <label class="content1 text-end">Operator</label>
  </div>

  <div class="row">
    <div class="col-5">
      <label class="label-content">3. Conditions:</label>
    </div>
  </div>

  <span class="content mt-1 mb-0 col-12">
    (i)  After 3 working days from the date of completion of packing falls the due date of delivery
    commodity. For these 3  days the  commodity can  be  stored in   packing centre free of  cost.
    Thereafter upto 15 days storage charges for 15 days and more then fifteen days storage charges
    for one month has to be paid. At any cost storage of packed commodity for more than a month in
    packing center is not allowed.
  </span><br>
  <span class="content col-12 mb-0">
    (ii) Packing Charges do not include Transport & Handling Charges; Stickering Charges and Bundling Charges
  </span><br>

  <div class="row mt-1">
    <div class="col-5">
      <label class="content1">4. Acknowledgement by Depositor :</label>
    </div>
  </div>

  <div class="row">
    <span class="label-content">
      Deposited the said commodity for the above mentioned packing and shall abide by all term and
      conditions. Storage charges / packing charges if any should be paid by us on or before taking
      delivery of goods.
    </span>
  </div>

  <div class="row mt-3 justify-content-end">
    <label class="label-content col-12 text-end">
      Signature of the Depositor/Authorized Representative
    </label>
  </div>
</div>
