import { TollGate } from "@components/aftc-digitalization/aftc-digitalization-models/TollGate";
import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ApiService } from "@services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, takeUntil } from "rxjs";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { WarehouseStockDeposit } from "@/components/aftc-digitalization/aftc-digitalization-models/warehouseStockDeposit";


@Component({
  selector: "app-deposit-unit-entry",
  templateUrl: "./deposit-unit-entry.component.html",
  styleUrls: ["./deposit-unit-entry.component.scss"]
})
export class DepositUnitEntryComponent implements OnInit {
  depositorDetails: WarehouseStockDeposit | undefined;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private apiServices: ApiService,private spinner: NgxSpinnerService,
              private router: Router, private toaster: ToastrService) {

  }

  depositForm = new UntypedFormGroup ({
    stockDepositId : new UntypedFormControl('', [Validators.required,Validators.nullValidator]),
    depositApplicationNo : new UntypedFormControl('', [Validators.required,Validators.nullValidator]),
    depositDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    depositorName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    invoiceNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    billDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    endDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    commodity: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    noOfBags: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    weight: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    typeOfVehicle: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    wheels: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehicleNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    amount: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    createdAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    updatedAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    createdBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    updatedBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    currentStage: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    irmReceiptNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    storageType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    serviceType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    billPeriodType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    qualityReportValue: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    qualityReportComments: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    qualityReportStatus: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    godownNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    stackNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    supplierName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
  })

  depositFormErrors = {
    depositApplicationNo : "",
    depositorName : "",
    supplierName : "",
    commodity: "",
    qualityReportValue: "",
    qualityReportComments: "",
    qualityReportStatus: "",
    godownNo: "",
    stackNo: "",
  }

  formValidation(from : any){
    this.depositFormErrors.depositApplicationNo = "";
    this.depositFormErrors.depositorName = "";
    this.depositFormErrors.supplierName = "";
    this.depositFormErrors.commodity = "";
    this.depositFormErrors.qualityReportValue = "";
    this.depositFormErrors.qualityReportComments = "";
    this.depositFormErrors.qualityReportStatus = "";
    this.depositFormErrors.godownNo = "";
    this.depositFormErrors.stackNo = "";
    let hasError = false;

    if(this.depositForm.get('depositApplicationNo').invalid){
      this.depositFormErrors.depositApplicationNo = "*Deposit Application No is Required"
      hasError = true;
    }
    if(this.depositForm.get('depositorName').invalid){
      this.depositFormErrors.depositorName = "*Depositor Name is Required"
      hasError = true;
    }
    if(this.depositForm.get('supplierName').invalid){
      this.depositFormErrors.supplierName = "*Supplier Name is Required"
      hasError = true;
    }
    if(this.depositForm.get('commodity').invalid){
      this.depositFormErrors.commodity = "*Commodity is Required"
      hasError = true;
    }
    if(this.depositForm.get('qualityReportValue').invalid){
      this.depositFormErrors.qualityReportValue = "*No of Bags is Required"
      hasError = true;
    }
    if(this.depositForm.get('qualityReportComments').invalid){
      this.depositFormErrors.qualityReportComments = "*Weight is Required"
      hasError = true;
    }
    if(this.depositForm.get('qualityReportStatus').invalid){
      this.depositFormErrors.qualityReportStatus = "*Type of Vehicle is Required"
      hasError = true;
    }
    if(this.depositForm.get('godownNo').invalid){
      this.depositFormErrors.godownNo = "*Wheels Count is Required"
      hasError = true;
    }
    if(this.depositForm.get('stackNo').invalid){
      this.depositFormErrors.stackNo = "*Vehicle Number is Required"
      hasError = true;
    }

    if(!hasError){
      console.log(this.depositForm.value);
      // this.addStockDeposit()
    }
  }

  ngOnInit() {
    const subReport = sessionStorage.getItem('depositId');
    if (!!subReport) {
      this.getDepositorDetailsById(subReport);
    }
    this.initial_data();
  }

  ngOnDestroy() {
    sessionStorage.removeItem('depositId')
  }

  getApplicationNo() {
    this.apiServices.getDepositAppNo()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        console.log("application No::", data);
       this.depositForm.controls["depositApplicationNo"].setValue(data.depositAppNo);
      }, (err: any) => {
        console.log("something went wrong::", err);
      })
  }

  initial_data() {
    const now = new Date();
    console.log(now);
    const localDateTime = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
    const formattedDateTime = localDateTime.toISOString().slice(0, 16);
    console.log("format :", formattedDateTime);
    this.depositForm.controls["depositDate"].setValue(formattedDateTime);
  }

  getDepositorDetailsById(depositorId) {
    this.apiServices.getDepositDetailsById(depositorId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        console.log("application No::", data);
        this.depositorDetails = data;
        this.depositForm.controls['depositApplicationNo'].setValue(this.depositorDetails.depositApplicationNo);
        this.depositForm.controls['depositorName'].setValue(this.depositorDetails.depositorName);
        this.depositForm.controls['commodity'].setValue(this.depositorDetails.commodity);
        this.depositForm.controls['noOfBags'].setValue(this.depositorDetails.noOfBags);
        this.depositForm.controls['weight'].setValue(this.depositorDetails.weight);
        this.depositForm.controls['typeOfVehicle'].setValue(this.depositorDetails.typeOfVehicle);
        this.depositForm.controls['wheels'].setValue(this.depositorDetails.wheels);
        this.depositForm.controls['vehicleNo'].setValue(this.depositorDetails.vehicleNo);
        this.depositForm.controls['amount'].setValue(this.depositorDetails.amount);
        this.depositForm.controls['irmReceiptNo'].setValue(this.depositorDetails.irmReceiptNo);
        this.depositForm.controls['storageType'].setValue(this.depositorDetails.storageType);
        this.depositForm.controls['serviceType'].setValue(this.depositorDetails.serviceType);
        this.depositForm.controls['invoiceNo'].setValue(this.depositorDetails.invoiceNo);
        this.depositForm.controls['supplierName'].setValue(this.depositorDetails.supplierName);
        this.depositForm.controls['billPeriodType'].setValue(this.depositorDetails.billPeriodType);
        const milliseconds = this.depositorDetails.declarationDate * 1000;
        const date = new Date(milliseconds);
        const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
        this.depositForm.controls['billDate'].setValue(formattedDate);
      }, (err: any) => {
        console.log("something went wrong::", err);
        this.toaster.error("Something went wrong, Please try again later");
      })
  }

  addStockDeposit(){
    this.spinner.show()
    const entryDate = this.depositForm.get("depositDate").value;
    const epochTimestamp = new Date(entryDate).getTime() / 1000;
    this.depositForm.controls["depositDate"].setValue(epochTimestamp);
    const billDate = this.depositForm.get("billDate").value;
    const epochStartTimestamp = new Date(billDate).getTime() / 1000;
    this.depositForm.controls["billDate"].setValue(epochStartTimestamp);
    this.depositForm.controls["createdAt"].setValue(Math.floor(new Date().getTime() / 1000));
    this.apiServices.addStockDeposit(this.depositForm.value)
    .pipe(takeUntil(this.destroy$))
    .subscribe((data) => {
      this.spinner.hide()
      console.log("saveStockDeposit::",data)
      this.saveAlert(data.stockDepositId)
    },(err : any) =>{
      this.spinner.hide()
      console.log("something went wrong")
    })
  }


  saveAlert(depositId: number) {
        Swal.fire({
            title: "Details Saved Successfully...",
            icon: "success",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            showClass: {
                popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
                popup: "animate__animated animate__fadeOutUp",
            },
        }).then((result) => {
            if (result.isConfirmed) {
              sessionStorage.setItem('depositorId', String(depositId));
               this.router.navigate(['/deposit-print']);
            }
        });
  }

}
