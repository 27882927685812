<nav class="navbar navbar-expand-lg navbar-light" style="background-color:rgb(81,201,99)">
  <div class="container-fluid">
    <p class="navbar-brand" href="#">Vehicle Module Data Entry</p>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav" type="button">
      <ul class="navbar-nav">
        <li class="nav-item">
          <p class="nav-link" routerLink="/main/depotDetails" href="#" [routerLinkActive]="['active']"
             [routerLinkActiveOptions]="{exact:true}">Vehicle Data Entry</p>
        </li>
        <li class="nav-item">
          <p class="nav-link" routerLink="/main/adminVehicleDetails" href="#" [routerLinkActive]="['active']"
             [routerLinkActiveOptions]="{exact:true}">Admin Vehicle Data View</p>
        </li>
        <li class="nav-item">
          <p class="nav-link" routerLink="/main/depotVehicleDetails" href="#" [routerLinkActive]="['active']"
             [routerLinkActiveOptions]="{exact:true}">Depot Vehicle Data View</p>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div class="container-fluid">
  <div class="row py-5"></div>
  <div class="col-md-8 justify-content-start">
  </div>

  <section>
    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center align-items-center justify-content-center">
          <div class="row">
            <div class="col-md-4 col-12">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" (click)="visibleForm('administrative')"
                       [checked]="selectedOption === 'administrative'" id="administrative">
                <label class="form-check-label" for="administrative">
                  Administrative Vehicle
                </label>
              </div>
            </div>
            <div class="col-md-4 col-12">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" (click)="visibleForm('depot')"
                       [checked]="selectedOption === 'depot'" id="depot">
                <label class="form-check-label" for="depot">
                  Depot / Service Data Entry
                </label>
              </div>
            </div>
            <div class="col-md-4 col-12">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" (click)="visibleForm('depotVehicle')"
                       [checked]="selectedOption === 'depotVehicle'" id="depotVehicle">
                <label class="form-check-label" for="depotVehicle">
                  Depot Vehicle
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="row" *ngIf="depotDetails">
    <div class="col-lg-2"></div>
    <div class="col-lg-8">
      <div class="row py-2"></div>
      <form [formGroup]="gatewayForm">
        <div class="card p-3" id="card">
          <div class="row py-2"></div>
          <h3 class="text-center">Depot Registration</h3>
          <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-5 col-md-6 col-12">
              <div class="form-floating mt-3">
                <select class="form-select" aria-label="Default select example"
                        formControlName="depotNo" placeholder="Depot Number">
                  <option selected>Select One</option>
                  <option *ngFor="let no of this.options">{{no}}</option>
                </select>
                <label>Depot Number</label>
              </div>
            </div>
            <div class="col-lg-5 col-md-6 col-12">
              <div class="form-floating mt-3">
                <input type="text" class="form-control" placeholder="Member Name"
                       formControlName="depotOccupantName">
                <label>Member Name</label>
              </div>
            </div>
            <div class="col-lg-1"></div>
          </div>
          <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-5 col-md-6 col-12">
              <div class="form-floating mt-3">
                <select class="form-select" aria-label="Default select example"
                        formControlName="aftcMembershipType" placeholder="Aftc Memember Type">
                  <option selected disabled>Choose a AFTC Memember Type</option>
                  <option value="SH">Stack Holder (SH)</option>
                  <option value="UM">Utility Member (UM)</option>
                </select>
                <label>AFTC Memember Type</label>
              </div>
              <span *ngIf="depositFormErrors.aftcMembershipType"
                    class="text-danger">{{depositFormErrors.aftcMembershipType}}</span>
            </div>
            <div class="col-lg-5 col-md-6 col-12 mt-3">
              <div class="form-floating">
                <ng-multiselect-dropdown
                  [placeholder]="'Select Service Type'"
                  [settings]="dropdownSettings"
                  [data]="dropdownList"
                  [(ngModel)]="selectedItems"
                  (onSelect)="onItemSelect($event)"
                  (onSelectAll)="onSelectAll($event)"
                  (change)="selectedServiceType($event)"
                  (onDeSelect)="onItemDeSelect($event)"
                  formControlName="serviceType"
                >
                </ng-multiselect-dropdown>
              </div>
              <span *ngIf="depositFormErrors.serviceType" class="text-danger">{{depositFormErrors.serviceType}}</span>
            </div>
            <div class="col-lg-1"></div>
          </div>
          <div class="text-center py-3"></div>
          <div class="text-center">
            <button type="button" class="btn btn-success" (click)="formValidationDepot()">Save</button>
          </div>
          <div class="text-center py-3"></div>
        </div>
      </form>
    </div>
  </div>


  <form [formGroup]="allVehicleDetails">
    <div class="row" *ngIf="administrativeVehicle">
      <div class="col-lg-2"></div>
      <div class="col-lg-8">
        <div class="row py-2"></div>
        <div class="card p-3" id="card">
          <div class="row py-2"></div>
          <h3 class="text-center">Administrative Office Vehicle Registration</h3>
          <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-5 col-md-6 col-12">
              <div class="form-floating mt-3">
                <input type="text" class="form-control" placeholder="Admin Name"
                       formControlName="adminName">
                <label>Name</label>
              </div>
              <span *ngIf="vehicleDetailsErrors.adminName" class="text-danger">{{vehicleDetailsErrors.adminName}}</span>
            </div>
            <div class="col-lg-5 col-md-6 col-12">
              <div class="form-floating mt-3">
                <select class="form-select" aria-label="Default select example"
                        formControlName="typeOfvehicle" placeholder="Vehicle Type"
                        (change)="selectVehicleTypeBrands($event)">
                  <option selected disabled>Select One</option>
                  <option value="Bike">Bike</option>
                  <option value="Auto">Auto</option>
                  <option value="Car">Car</option>
                  <option value="Van">Van</option>
                  <option value="Lorry">Lorry</option>
                  <option value="Truck">Truck</option>
                </select>
                <label>Vehicle Type</label>
              </div>
              <span *ngIf="vehicleDetailsErrors.typeOfvehicle"
                    class="text-danger">{{vehicleDetailsErrors.typeOfvehicle}}</span>
            </div>
            <div class="col-lg-1"></div>
          </div>
          <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-1"></div>
          </div>
          <div class="row">
            <div class="col-lg-1"></div>

            <div class="col-lg-5 col-md-6 col-12">
              <div class="form-floating mt-3">
                <input type="text" class="form-control" placeholder="eg: TN-00-AA-0000"
                       (input)="formatVehicleNumber($event)"
                       formControlName="registrationNumber">
                <label>Vehicle Reg Number</label>
              </div>
              <span class="p text-danger"
                    *ngIf="vehicleDetailsErrors.registrationNumber">{{vehicleDetailsErrors.registrationNumber}}</span>
            </div>
          </div>
          <div class="text-center py-3"></div>
          <div class="text-center">
            <button type="button" class="btn btn-success" (click)="formValidationVehicleEntry()">Save</button>
          </div>
          <div class="text-center py-3"></div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="depotVehicle">
      <div class="col-lg-2"></div>
      <div class="col-lg-8">
        <div class="row py-2"></div>
        <div class="card p-3" id="card">
          <div class="row py-2"></div>
          <h3 class="text-center itallic">Depot Vehicle Registration</h3>
          <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-5 col-md-6 col-12">
              <div class="form-floating mt-3">
                <select class="form-select" aria-label="Default select example"
                        formControlName="depotNumber" placeholder="Depot Number" (change)="depotNo($event)">
                  <option selected>Select One</option>
                  <option *ngFor="let no of this.getDepotNo">{{no}}</option>
                </select>
                <label>Depot Number</label>
              </div>
              <span *ngIf="vehicleDetailsErrors.depotNumber"
                    class="text-danger">{{vehicleDetailsErrors.depotNumber}}</span>
            </div>
            <div class="col-lg-5 col-md-6 col-12">
              <div class="form-floating mt-3">
                <input type="text" class="form-control"
                       formControlName="depotName">
                <label>Depot Occupant Name</label>
              </div>
            </div>
            <div class="col-lg-1"></div>
          </div>
          <div class="row">
            <div class="col-lg-1"></div>
            <div class="col-lg-5 col-md-6 col-12">
              <div class="form-floating mt-3">
                <select class="form-select" aria-label="Default select example"
                        formControlName="typeOfvehicle" placeholder="Vehicle Type"
                        (change)="selectVehicleTypeBrands($event)">
                  <option selected disabled>Select One</option>
                  <option value="Bike">Bike</option>
                  <option value="Auto">Auto</option>
                  <option value="Car">Car</option>
                  <option value="Van">Van</option>
                  <option value="Lorry">Lorry</option>
                  <option value="Truck">Truck</option>
                </select>
                <label>Vehicle Type</label>
              </div>
              <span *ngIf="vehicleDetailsErrors.typeOfvehicle"
                    class="text-danger">{{vehicleDetailsErrors.typeOfvehicle}}</span>
            </div>

            <div class="col-lg-5 col-md-6 col-12">
              <div class="form-floating mt-3">
                <input type="text" class="form-control" placeholder="eg: TN-00-AA-0000"
                       (input)="formatVehicleNumber($event)"
                       formControlName="registrationNumber">
                <label>Vehicle Reg Number</label>
              </div>
              <span class="p text-danger"
                    *ngIf="vehicleDetailsErrors.registrationNumber">{{vehicleDetailsErrors.registrationNumber}}</span>
            </div>
            <div class="col-lg-1"></div>
          </div>
          <div class="text-center py-3"></div>
          <div class="text-center">
            <button type="button" class="btn btn-success" (click)="formValidationVehicleEntry()">Save</button>
          </div>
          <div class="text-center py-3"></div>
        </div>
      </div>
    </div>
    <div class="col-md-4"></div>
  </form>
</div>
<ngx-spinner>
  <div class="loaderssm">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
</ngx-spinner>
