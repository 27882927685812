import { ApiService } from "@services/api.service";
import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup, UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { TollGate } from "@components/aftc-digitalization/aftc-digitalization-models/TollGate";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { depotDetails } from "@/model/depotDetails/depotDetails";
import { CommodityDetails } from "@/model/commodityDetails/CommodityDetails";

@Component({
  selector: "app-toll-gate-form",
  templateUrl: "./toll-gate-form.component.html",
  styleUrls: ["./toll-gate-form.component.css"]
})
export class TollGateFormComponent implements OnInit {
  Purposes: string[] = ["Loading", "Un Loading"];
  selectedPurpose: string = "Loading";
  todayDate: any;
  datepipe: any;
  heavyForm: any;
  vForm: any;
  aForm: any;
  qrCodeGenerate = false;
  vehicleNumber: string;
  currentTollGate: TollGate | undefined;
  vehicleOption: string[] = [];
  wheelOptions: number[] = [];
  heavy = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  serviceType = false;
  userType: string;
  futureDateDisable: string | null;
  showFields: number;
  loadType: boolean = false;

  allMemberDetails: depotDetails[] = [];
  currentMemberDetails: depotDetails[] = [];
  currentMemberNames: string[] = [];
  commodityDetails: CommodityDetails[] = [];
  commodityNames: string[] = [];

  dropdownList = [];
  selectedItem = this.dropdownList;
  dropdownSettings = {};

  constructor(private apiService: ApiService, datepipe: DatePipe,
    private spinner: NgxSpinnerService, private router: Router,
    private translate: TranslateService, private toaster: ToastrService) {
    this.datepipe = datepipe;
    this.todayDate = this.datepipe.transform(new Date(), "yyyy-MM-dd");
    this.futureDateDisable = this.datepipe.transform(new Date(), 'yyyy-MM-ddTHH:mm');
    this.initial_data();
    this.translate.addLangs(['en', 'ta']);
    this.translate.setDefaultLang('en'); // Default language
    this.dropdownSettings = {
      singleSelection: true,
      idField: 'item_id',
      textField: 'item_text',
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No data available',
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      allowRemoteDataSearch: false,
      itemsShowLimit: 3,
      lazyLoading: false,
      selectAllText: '',
      unSelectAllText: '',
    };
  }

  gateForm = new UntypedFormGroup({
    tollgateSeq: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    memberName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    tollgateType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    tollgateTypeSeq: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehEntryDateTime: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehExitDateTime: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehCost: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    renterSeq: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    renterEntryName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    commodityName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    weightMt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    bagsUnits: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    consignorSupplierName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    placeDispatchOrigin: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    deliveryWayBill: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    declarationDate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    purpose: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    charges: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    renterAgentName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    securityClerk: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    passPrice: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    reportQr: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    isActive: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    createdAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    modifiedAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    tollgateVehicleDetails: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehicleSeq: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehicleQr: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    wheelCount: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    driverName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    driverPhNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehInfo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehNumberPlate: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehState: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    vehType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    serviceType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    cashDeposit: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    storageType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    modeOfPayment: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
  });

  // Save tollgate details
  saveForm() {
    this.spinner.show();
    this.gateForm.controls["charges"].enable();
    const entryDate = this.gateForm.get("vehEntryDateTime").value;
    console.log(entryDate)
    const epochTimestamp = new Date(entryDate).getTime() / 1000;
    this.gateForm.controls["vehEntryDateTime"].setValue(epochTimestamp);
    const declarationDate = this.gateForm.get("declarationDate").value;
    const epochTimeDeclarationDate = new Date(declarationDate).getTime() / 1000;
    this.gateForm.controls["declarationDate"].setValue(epochTimeDeclarationDate);
    this.gateForm.controls["createdAt"].setValue(Math.floor(new Date().getTime() / 1000));
    const tollGateDetails = this.gateForm.value;
    this.gateForm.controls["cashDeposit"].setValue(false);

    // For nested json combined the tollgate & vehicle details
    const combinedData = {
      tollgateSeq: tollGateDetails.tollgateSeq,
      tollgateType: tollGateDetails.tollgateType,
      memberName: tollGateDetails.memberName[0].item_text,
      commodityName: tollGateDetails.commodityName,
      tollgateTypeSeq: tollGateDetails.tollgateTypeSeq,
      vehEntryDateTime: tollGateDetails.vehEntryDateTime,
      vehExitDateTime: tollGateDetails.vehExitDateTime,
      vehCost: tollGateDetails.vehCost,
      renterSeq: tollGateDetails.renterSeq,
      renterEntryName: tollGateDetails.renterEntryName,
      serviceType: tollGateDetails.serviceType,
      weightMt: tollGateDetails.weightMt,
      purpose: tollGateDetails.purpose,
      charges: tollGateDetails.charges,
      renterAgentName: tollGateDetails.renterAgentName,
      securityClerk: tollGateDetails.securityClerk,
      passPrice: tollGateDetails.passPrice,
      reportQr: tollGateDetails.reportQr,
      active: tollGateDetails.active,
      createdAt: tollGateDetails.createdAt,
      modifiedAt: tollGateDetails.modifiedAt,
      modeOfPayment: tollGateDetails.modeOfPayment,
      tollgateVehicleDetails: {
        vehicleSeq: tollGateDetails.vehicleSeq,
        vehicleQr: tollGateDetails.vehicleQr,
        wheelCount: tollGateDetails.wheelCount,
        driverName: tollGateDetails.driverName,
        driverPhNo: tollGateDetails.driverPhNo,
        vehInfo: tollGateDetails.vehInfo,
        vehNumberPlate: tollGateDetails.vehNumberPlate.toUpperCase(),
        vehState: tollGateDetails.vehState,
        vehType: tollGateDetails.vehType
      }
    };
    console.log(combinedData);

    if (combinedData.vehEntryDateTime.toString().length == 10) {
      console.log(combinedData.vehEntryDateTime.toString().length);
      this.apiService.addTollGate(combinedData)
        .pipe(takeUntil(this.destroy$))
        .subscribe(data => {
          this.spinner.hide();
          this.currentTollGate = data;
          this.qrCodeGenerate = true;
          sessionStorage.setItem("reportQr", this.currentTollGate.reportQr);
          this.router.navigate(["tollgate-receipt"]);
          // Swal.fire({
          //   title: "Do you want print?",
          //   icon: "warning",
          //   showCancelButton: true,
          //   showDenyButton: true,
          //   denyButtonColor: "#3091d6",
          //   confirmButtonColor: "#30d66a",
          //   cancelButtonColor: "#d33",
          //   confirmButtonText: "A5 Print",
          //   denyButtonText: "Thermal Print",
          //   cancelButtonText: "No Print"
          // }).then((result) => {
          //   if (result.isConfirmed) {
          //     sessionStorage.setItem("reportQr", this.currentTollGate.reportQr);
          //     this.router.navigate(["toll-gate-print"]);
          //   } else if (result.isDenied) {
          //     sessionStorage.setItem("reportQr", this.currentTollGate.reportQr);
          //     this.router.navigate(["tollgate-receipt"]);
          //   } else {
          //     Swal.fire({
          //       title: "Details saved successfully",
          //       icon: "success"
          //     }).then((res) => {
          //       this.spinner.show();
          //       window.location.reload();
          //     });
          //   }
          // });
          this.gateForm.reset();
          this.initial_data();
        }, (error: any) => {
          this.spinner.hide();
        });
    } else {
      this.toaster.error("Something went wrong, time not get properly. Please try again");
      location.reload();
    }
    
  }

  ngOnInit(): void {
    this.userType = localStorage.getItem("role");
    this.getMemberDetails();
    this.getCommodityDetails();
    this.initial_data();
    Object.keys(this.gateForm.controls).forEach((controlName) => {
      if (controlName !== "serviceType") {
        this.gateForm.get(controlName).disable();
      }
    });
  }

  ngOnDestroy(): void {
    this.translate.use('en');
  }

  // Get all member details
  getMemberDetails() {
    this.spinner.show();
    this.apiService.getDepotDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.spinner.hide();
        this.allMemberDetails = data.filter(item => item.records == "NEW");
      }, (err: any) => {
        this.spinner.hide();
        this.toaster.error(err.error.message || "Member details not getting, Please try again later");
      });
  }

  // Get all commodity details
  getCommodityDetails() {
    this.apiService.getCommodityDetails()
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.commodityDetails = data;
        const set = new Set(this.commodityDetails.map(item => item.commodityName));
        this.commodityNames = Array.from(set).sort((a, b) => a.localeCompare(b));
      }, (err: any) => {
        this.toaster.error(err.error.message || "Commodity details not getting, Please try again later");
      });
  }

  // Get current Date
  initial_data() {
    const now = new Date();
    const localDateTime = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
    const formattedDateTime = localDateTime.toISOString().slice(0, 16);
    this.gateForm.controls["vehEntryDateTime"].setValue(formattedDateTime);
    this.gateForm.controls["vehState"].setValue("Tamil Nadu");
  }

  // Field conditions based on selection of service type
  selectServiceType(event: any) {
    this.vehicleOption = [];
    this.currentMemberDetails = [];
    this.currentMemberNames = [];
    const value = event.target.value;
    if (value == "Warehouse") {
      this.gateForm.enable();
      this.gateForm.controls["charges"].disable();
      this.vehicleOption.push("Auto");
      this.vehicleOption.push("Van");
      this.vehicleOption.push("Truck");
      this.gateForm.controls["vehType"].setValue("");
      this.gateForm.controls["weightMt"].setValue("");
      this.gateForm.controls["purpose"].setValue("");
      // this.showFields = 2;
    } else if (value == "Depot") {
      this.gateForm.enable();
      this.gateForm.controls["charges"].disable();
      this.vehicleOption.push("Auto");
      this.vehicleOption.push("Van");
      this.vehicleOption.push("Truck");
      this.gateForm.controls["vehType"].setValue("");
      this.gateForm.controls["weightMt"].setValue("");
      this.gateForm.controls["purpose"].setValue("");
      // this.showFields = 1;
    }
    this.showFields = 1;
    this.currentMemberDetails = this.allMemberDetails.sort((a, b) => a.depotOccupantName.localeCompare(b.depotOccupantName));
    this.dropdownList = this.currentMemberDetails.map(member => {
      var returnValue = {};
      if (member.depotNo && member.slDepotNo) {
        returnValue = { item_id: member.depotDetailsId, item_text: member.depotOccupantName + ' / ' + member.depotNo + ' - SL ' + member.slDepotNo }
      } else if (member.depotNo) {
        returnValue = { item_id: member.depotDetailsId, item_text: member.depotOccupantName + ' / ' + member.depotNo}
      } else if (member.slDepotNo) {
        returnValue = { item_id: member.depotDetailsId, item_text: member.depotOccupantName + ' / SL ' + member.slDepotNo }
      } else {
        returnValue = { item_id: member.depotDetailsId, item_text: member.depotOccupantName }
      }
      return returnValue;
    });
  }

  selectMember(event) {
    if (this.showFields < 2) {
      this.showFields = 2
    }
  }

  // Number only acceptable condition
  OnlyNumbers($event) {
    let regex: RegExp = new RegExp(/^[0-9]{1,}$/g);
    let specialKeys: Array<string> = ["Backspace", "Tab", "End", "Home", "ArrowRight", "ArrowLeft"];
    if (specialKeys.indexOf($event.key) !== -1) {
      return;
    } else {
      if (regex.test($event.key)) {
        return true;
      } else {
        return false;
      }
    }
  }

  formErrorValidation = {
    memberName: "",
    commodityName: "",
    vehNumberPlate: "",
    vehEntryTime: "",
    vehType: "",
    wheelCount: "",
    serviceType: "",
    printType: "",
    purpose: "",
    weightMt: ""
  };

  // Required field validation conditions
  formValidation(form: any) {
    this.formErrorValidation.memberName = "";
    this.formErrorValidation.commodityName = "";
    this.formErrorValidation.vehEntryTime = "";
    this.formErrorValidation.vehNumberPlate = "";
    this.formErrorValidation.vehType = "";
    this.formErrorValidation.wheelCount = "";
    this.formErrorValidation.serviceType = "";
    this.formErrorValidation.printType = "";
    this.formErrorValidation.purpose = "";
    this.formErrorValidation.weightMt = "";
    let hasError = false;

    if (this.gateForm.get("vehType").value == "Truck") {
      if (this.gateForm.get("purpose").invalid) {
        this.formErrorValidation.purpose = "Purpose is Required";
        hasError = true;
      }

      if (this.gateForm.get("purpose").value == "Unloading" && this.gateForm.get("weightMt").invalid) {
        this.formErrorValidation.weightMt = "Weight MT is Required";
        hasError = true;
      }

      if (this.gateForm.get("purpose").value == "Unloading" && this.gateForm.get("commodityName").invalid) {
        this.formErrorValidation.commodityName = "Commodity Name is Required";
        hasError = true;
      }
    }

    if (this.gateForm.get("vehNumberPlate").invalid) {
      this.formErrorValidation.vehNumberPlate = "Vehicle Number is Required";
      hasError = true;
    }

    if (this.gateForm.get("memberName").invalid) {
      this.formErrorValidation.memberName = "Member Name is Required";
      hasError = true;
    }

    if (this.gateForm.get("vehEntryDateTime").invalid) {
      this.formErrorValidation.vehEntryTime = "Vehicle Entry Time is Required";
      hasError = true;
    }

    if (this.gateForm.get("vehType").invalid) {
      this.formErrorValidation.vehType = "Vehicle Type is Required";
      hasError = true;
    }

    if (this.gateForm.get("wheelCount").invalid) {
      this.formErrorValidation.wheelCount = "Wheel Count is Required";
      hasError = true;
    }

    if (this.gateForm.get("serviceType").invalid) {
      this.formErrorValidation.serviceType = "Service Type is Required";
      hasError = true;
    }

    if (!hasError) {
      this.saveForm();
    }
  }

  // Wheels count set based on selected vehicle type
  selectVehicleType(type: string) {
    const value = type;
    this.wheelOptions = [];
    var wheel: number[] = [];
    if (value == "Auto") {
      wheel.push(Number(3));
      this.gateForm.controls["wheelCount"].setValue(Number(3));
      this.gateForm.controls["charges"].setValue(Number(5));
      this.gateForm.controls["modeOfPayment"].setValue('Cash on Hand');
      this.heavy = false;
      this.showFields = 8;
    } else if (value == "Van") {
      wheel.push(Number(4));
      this.gateForm.controls["wheelCount"].setValue(Number(4));
      this.gateForm.controls["charges"].setValue(Number(10));
      this.gateForm.controls["modeOfPayment"].setValue('Cash on Hand');
      this.heavy = false;
      this.showFields = 8;
    } else if (value == "Truck") {
      wheel.push(Number(6));
      wheel.push(Number(10));
      wheel.push(Number(12));
      wheel.push(Number(14));
      this.gateForm.controls["wheelCount"].setValue("");
      this.heavy = true;
      this.showFields = 3;
    }
    this.wheelOptions = wheel;
  }

  // When change the wheel again select the purpose field
  wheelCountFunction(event: any) {
    this.gateForm.controls["purpose"].setValue("");
    this.gateForm.controls["charges"].setValue("");
    if (this.showFields < 4) {
      this.showFields = 4;
    }
  }

  // Charges calculation based on purpose
  chargeCal(event: any) {
    const value = event.target.value;
    if (value == "Loading") {
      if (this.gateForm.get("wheelCount").value == Number(6)) {
        this.gateForm.controls["charges"].setValue(Number(150));
      } else if (this.gateForm.get("wheelCount").value > Number(6)) {
        this.gateForm.controls["charges"].setValue(Number(200));
      }
      this.gateForm.controls["weightMt"].setValue("");
      this.gateForm.controls["weightMt"].disable();
      this.loadType = false;
    } else if (value == "Unloading") {
      const weightValue = this.gateForm.get("weightMt").value;
      const weight = Math.round(weightValue);
      if (this.gateForm.get("vehState").value == "Tamil Nadu") {
        const charge = weight * Number(15);
        if (this.gateForm.get("wheelCount").value == Number(6) && charge < Number(150)) {
          this.gateForm.controls["charges"].setValue(Number(150));
        } else if (this.gateForm.get("wheelCount").value == Number(6) && charge > Number(150)) {
          this.gateForm.controls["charges"].setValue(weight * Number(15));
        } else if (this.gateForm.get("wheelCount").value > Number(6) && charge < Number(200)) {
          this.gateForm.controls["charges"].setValue(Number(200));
        } else if (this.gateForm.get("wheelCount").value > Number(6) && charge > Number(200)) {
          this.gateForm.controls["charges"].setValue(weight * Number(15));
        }
      } else {
        const charge = weight * Number(30);
        if (this.gateForm.get("wheelCount").value == Number(6) && charge < Number(150)) {
          this.gateForm.controls["charges"].setValue(Number(150));
        } else if (this.gateForm.get("wheelCount").value == Number(6) && charge > Number(150)) {
          this.gateForm.controls["charges"].setValue(weight * Number(30));
        } else if (this.gateForm.get("wheelCount").value > Number(6) && charge < Number(200)) {
          this.gateForm.controls["charges"].setValue(Number(200));
        } else if (this.gateForm.get("wheelCount").value > Number(6) && charge > Number(200)) {
          this.gateForm.controls["charges"].setValue(weight * Number(30));
        }
      }
      this.gateForm.controls["weightMt"].enable();
      this.loadType = true;
    }
    if (this.showFields < 5) {
      this.showFields = 8;
      this.gateForm.controls["modeOfPayment"].setValue('Cash on Hand');
    }
  }

  // Charges calculation based on state
  changeState(event: any) {
    const value = event.target.value;
    const weight = Math.round(this.gateForm.get("weightMt").value);
    if (this.gateForm.get("purpose").value == "Unloading") {
      if (value == "Tamil Nadu") {
        const charge = weight * Number(15);
        if (this.gateForm.get("wheelCount").value == Number(6) && charge < Number(150)) {
          this.gateForm.controls["charges"].setValue(Number(150));
        } else if (this.gateForm.get("wheelCount").value == Number(6) && charge > Number(150)) {
          this.gateForm.controls["charges"].setValue(weight * Number(15));
        } else if (this.gateForm.get("wheelCount").value > Number(6) && charge < Number(200)) {
          this.gateForm.controls["charges"].setValue(Number(200));
        } else if (this.gateForm.get("wheelCount").value > Number(6) && charge > Number(200)) {
          this.gateForm.controls["charges"].setValue(weight * Number(15));
        }
      } else {
        const charge = weight * Number(30);
        if (this.gateForm.get("wheelCount").value == Number(6) && charge < Number(150)) {
          this.gateForm.controls["charges"].setValue(Number(150));
        } else if (this.gateForm.get("wheelCount").value == Number(6) && charge > Number(150)) {
          this.gateForm.controls["charges"].setValue(weight * Number(30));
        } else if (this.gateForm.get("wheelCount").value > Number(6) && charge < Number(200)) {
          this.gateForm.controls["charges"].setValue(Number(200));
        } else if (this.gateForm.get("wheelCount").value > Number(6) && charge > Number(200)) {
          this.gateForm.controls["charges"].setValue(weight * Number(30));
        }
      }
    }
    if (this.showFields < 6) {
      this.showFields = 6;
    }
  }

  // Charges calculation based on weight
  calculateCharges(event: any) {
    const value = event.target.value;
    const weight = Math.round(value);
    console.log(weight);
    if (this.gateForm.get("vehState").value == "Tamil Nadu") {
      const charge = weight * Number(15);
      if (this.gateForm.get("wheelCount").value == Number(6) && charge < Number(150)) {
        this.gateForm.controls["charges"].setValue(Number(150));
      } else if (this.gateForm.get("wheelCount").value == Number(6) && charge > Number(150)) {
        this.gateForm.controls["charges"].setValue(weight * Number(15));
      } else if (this.gateForm.get("wheelCount").value > Number(6) && charge < Number(200)) {
        this.gateForm.controls["charges"].setValue(Number(200));
      } else if (this.gateForm.get("wheelCount").value > Number(6) && charge > Number(200)) {
        this.gateForm.controls["charges"].setValue(weight * Number(15));
      }
    } else {
      const charge = weight * Number(30);
      if (this.gateForm.get("wheelCount").value == Number(6) && charge < Number(150)) {
        this.gateForm.controls["charges"].setValue(Number(150));
      } else if (this.gateForm.get("wheelCount").value == Number(6) && charge > Number(150)) {
        this.gateForm.controls["charges"].setValue(weight * Number(30));
      } else if (this.gateForm.get("wheelCount").value > Number(6) && charge < Number(200)) {
        this.gateForm.controls["charges"].setValue(Number(200));
      } else if (this.gateForm.get("wheelCount").value > Number(6) && charge > Number(200)) {
        this.gateForm.controls["charges"].setValue(weight * Number(30));
      }
    }
    if (this.showFields < 8) {
      this.showFields = 8;
    }
  }

  // Vehicle number input field format condition
  // formatVehicleNumber(event: any) {
  //   const input = event.target as HTMLInputElement;
  //   let value = input.value.replace(/\W/g, "").toUpperCase();

  //   let positions;
  //   let hyphenPositions;
  //   const split = value.split("");

  //   if (!isNaN(Number(split[5]))) {
  //     positions = [
  //       { start: 0, end: 1, regex: /[^A-Z]/g },
  //       { start: 2, end: 3, regex: /[^\d]/g },
  //       { start: 4, end: 4, regex: /[^A-Z]/g },
  //       { start: 5, end: 8, regex: /[^\d]/g }
  //     ];
  //     hyphenPositions = [2, 5, 7];
  //     if (value.length == 10) {
  //       this.formErrorValidation.vehNumberPlate = "*Enter Valid Register Number";
  //     } else {
  //       this.formErrorValidation.vehNumberPlate = "";
  //     }
  //   } else if (!isNaN(Number(split[6]))) {
  //     positions = [
  //       { start: 0, end: 1, regex: /[^A-Z]/g },
  //       { start: 2, end: 3, regex: /[^\d]/g },
  //       { start: 4, end: 5, regex: /[^A-Z]/g },
  //       { start: 6, end: 9, regex: /[^\d]/g }
  //     ];
  //     hyphenPositions = [2, 5, 8];
  //     if (value.length == 11) {
  //       this.formErrorValidation.vehNumberPlate = "*Enter Valid Register Number";
  //     } else {
  //       this.formErrorValidation.vehNumberPlate = "";
  //     }
  //   } else {
  //     positions = [
  //       { start: 0, end: 1, regex: /[^A-Z]/g },
  //       { start: 2, end: 3, regex: /[^\d]/g },
  //       { start: 4, end: 6, regex: /[^A-Z]/g }
  //     ];
  //     hyphenPositions = [2, 5, 9];
  //   }
  //   positions.forEach(pos => {
  //     value = value.replace(pos.regex, (match, offset) =>
  //       (offset >= pos.start && offset < pos.end) ? "" : match);
  //   });
  //   value = value.slice(0, 11);
  //   hyphenPositions.forEach(pos => {
  //     if (value.length > pos) {
  //       value = value.slice(0, pos) + "-" + value.slice(pos);
  //     }
  //   });
  //   this.gateForm.controls["vehNumberPlate"].setValue(value);
  // }

  switchLanguage(lang: string) {
    this.translate.use(lang);
  }
}



