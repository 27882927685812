<!-- START RECEIPT -->
<div class="receipt">
  <div class="">
    <div class="row text-center">
      <div class="col-2">
        <img src="../../../../../assets/img/aftc-icon.png" alt="Logo"
             style="text-align: center;height: 150px;width: 150px;">
      </div>
      <div class="col-10 mt-3">
        <h4 class="text-uppercase text-bold mt-2 headerTitle">Agro Food Trade Centre</h4>
        <div class="text-center headerSubTitle">
          Sikkanthar Chavadi, Alanganallur Road, Madurai-625018
        </div>
      </div>
    </div>
  </div>
  <hr>
  <div id="location">
    <div class="text-bold text-uppercase text-center location"><u>Internal Road Maintenance Charges Receipt</u></div>
  </div>

  <div class="row mt-3">
    <table class="table table-borderless">
      <thead>
      <tr>
        <th class="col-6">No-{{currentGatePass.irmNo}}</th>
        <!-- <th class="col-6">No - 2024-25/Van/0357</th> -->
        <th>Date: {{currentGatePass.vehEntryDateTime * 1000 | date: 'dd-MM-yyyy'}}</th>
      </tr>
      <tr *ngIf="currentGatePass.tollgateVehicleDetails.vehType == 'Truck'">
        <td>Vehicle Type:</td>
        <td *ngIf="currentGatePass.tollgateVehicleDetails.wheelCount == 6">
        {{currentGatePass.tollgateVehicleDetails.vehType}} (Upto 6 Wheels)</td>
        <td *ngIf="currentGatePass.tollgateVehicleDetails.wheelCount > 6">
          {{currentGatePass.tollgateVehicleDetails.vehType}} (More than 6 Wheels)</td>
          <td *ngIf="currentGatePass.tollgateVehicleDetails.wheelCount <= 4">
            {{currentGatePass.tollgateVehicleDetails.vehType}}</td>
      </tr>
      <tr>
        <td>Vehicle No:</td>
        <td>{{currentGatePass.tollgateVehicleDetails.vehNumberPlate}}</td>
      </tr>
      <tr>
        <td>Vehicle Region:</td>
        <td>{{currentGatePass.tollgateVehicleDetails.vehState}}</td>
      </tr>
      <tr *ngIf="currentGatePass.purpose == 'Unloading'">
        <td>Weights in MT:</td>
        <td>{{currentGatePass.weightMt}}</td>
      </tr>
      <tr *ngIf="currentGatePass.purpose == 'Unloading'">
        <td>Commodity:</td>
        <td>{{currentGatePass.commodityName}}</td>
      </tr>
      <tr *ngIf="currentGatePass.tollgateVehicleDetails.vehType == 'Truck'">
        <td>Type:</td>
        <td *ngIf="currentGatePass.purpose == 'Loading'">Empty</td>
        <td *ngIf="currentGatePass.purpose == 'Unloading'">With Goods</td>
      </tr>
      <tr>
        <td>Time In:</td>
        <td>{{currentGatePass.vehEntryDateTime * 1000 | date: 'HH:mm'}}</td>
      </tr>
      <tr>
        <td>Member Name:</td>
        <td>{{currentGatePass.memberName}}</td>
      </tr>
      <tr>
        <td>Driver Name:</td>
        <td *ngIf="currentGatePass.tollgateVehicleDetails.driverName">{{currentGatePass.tollgateVehicleDetails.driverName}}</td>
        <td *ngIf="!currentGatePass.tollgateVehicleDetails.driverName"> - </td>
      </tr>
      <tr>
        <td>Phone No:</td>
        <td *ngIf="currentGatePass.tollgateVehicleDetails.driverPhNo">{{currentGatePass.tollgateVehicleDetails.driverPhNo}}</td>
        <td *ngIf="!currentGatePass.tollgateVehicleDetails.driverPhNo"> - </td>
      </tr>
      <tr>
        <td>Mode of Payment:</td>
        <td>{{currentGatePass.modeOfPayment}}</td>
      </tr>
      <tr class="amount">
        <td>Received:</td>
        <td>
          <h5 class="price"> ₹ {{currentGatePass.charges }} /-</h5>
          <!-- <h5 class="price"> ₹ 100 /-</h5> -->
        </td>
      </tr>
      </thead>
    </table>
  </div>
  <div class="">
    <div class="row mt-3">
      <div class="col-3"></div>
      <div class="col-4 text-center">
        <img class="" src="{{vehicleQrCodeUrl}}" style="height: 500px; border: 4px solid #c54848">
      </div>
      <div class="col-4"></div>
    </div>
  </div>
  <div class="keepIt">
    <div class="row mt-4 mb-5">
      <div class="col-12 text-center keepIt">
        <h6 class="keepIt">Valid for single trip only</h6>
        <h5 class="keepIt font-italic">***** www.aftc.in *****</h5>
      </div>
    </div>
  </div>
</div>
