import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LocalDataServiceService } from "@services/local-data-service.service";
import { TollGate } from "@components/aftc-digitalization/aftc-digitalization-models/TollGate";
import { MemberDetails } from "@/model/memberDetails/MemberDetails";
import { CommodityDetails } from "@/model/commodityDetails/CommodityDetails";
import { AllTestDetails } from "@/model/labModal/allTestDetails";
import { UntypedFormGroup } from "@angular/forms";
import { TestDetails } from "@/model/labModal/testDetails";
import { InternalTestDetails } from "@/model/labModal/internalTestDetails";
import { ProcedureDetails } from "@/model/labModal/procedureDetails";
import { ProcedureResults } from "@/model/labModal/procedureResults";
import { NormalTestDetails } from "@/model/labModal/normalTestDetails";
import {
  WarehouseStockDeposit
} from "@/components/aftc-digitalization/aftc-digitalization-models/warehouseStockDeposit";
import {
  warehouseStockDelivery
} from "@/components/aftc-digitalization/aftc-digitalization-models/warehouseStockDelivery";
import { ApplicationNumber, TransactionNumber } from "@/model/applicationNumber/ApplicationNumber";
import { getTechnicalAnalystName } from "@/model/labModal/getTechnicalAnalystName";
import { CameraRecords } from "@/model/cameraRecords/CameraRecords";
import { StockDetails } from "@/model/stockDetails/stockDetails";
import { getUrlNo } from "@/model/labModal/urlNo";
import { DepositorDetails } from "@/model/stockDetails/depositorDetails";
import { StackNumberDetails } from "@/model/stockDetails/stackNumberDetails";
import { NewsFeed } from "@/model/newsFeed/newsFeed";
import { AftcMembershipDetails } from "@/model/membershipDetails/aftc-membershipDetails";
import { ConfigService } from "@/config.service";
import { AdminVehicleDetails } from "@/model/adminVehicleDetails/adminVehicleDetails";
import { DepotVehicleDetails } from "@/model/depotVehicleDetails/depotVehicleDetails";
import { depotDetails } from "@/model/depotDetails/depotDetails";
import { VehicleQRPrintData } from "@/model/vehicleDetails/VehicleQRPrintData";
import { AllVehicleRegDetails } from "@/model/all-vehicle-details/VehicleRegDetails";
import { vehiclePunchDetails } from "@/model/vehiclePunchDetails/vehiclPunchDetails";
import { TollGateCashDetails } from "@components/aftc-digitalization/aftc-digitalization-models/tollGateCashDetails";
import {
  PreprocessingDepositDetails
} from "@/components/aftc-digitalization/aftc-digitalization-models/preprocessingDepositDetails";
import {
  ColdStorageDepositDetails
} from "@components/aftc-digitalization/aftc-digitalization-models/coldStorageDepositDetails";
import {
  ColdStorageDeliveryDetails
} from "@components/aftc-digitalization/aftc-digitalization-models/coldStorageDeliveryDetails";
import {
  PackingDepositDetails
} from "@components/aftc-digitalization/aftc-digitalization-models/packingDepositDetails";
import { WeighBridgeDetails } from "@components/aftc-digitalization/aftc-digitalization-models/weighBridgeDetails";
import {
  WeighBridgeCashDetails
} from "@components/aftc-digitalization/aftc-digitalization-models/weighBridgeCashDetails";
import { SolarTunnelDryerDepositDetails } from "@components/aftc-digitalization/aftc-digitalization-models/solarTunnelDryerDepositDetails";

@Injectable({
  providedIn: "root"
})

export class ApiService {
  addWeightBridgeRecord(form: any) {
    throw new Error("Method not implemented.");
  }

  baseUrl = "https://tomcat3.varnik.cloud:8443/aftc-ws";
  token: string;

  constructor(private http: HttpClient, private localData: LocalDataServiceService, private configService: ConfigService) {
    this.token = localStorage.getItem("authHeader");
  }

  /*addGatePass(gatePassForm: any) {
    const headers = { 'content-type': 'application/json', 'Authorization': `Bearer ${this.token}`  };
    const add_url = this.configService.getConfig().END_POINT + '/api/addGatePass';

    const body = JSON.stringify(gatePassForm);
    console.log(body);
    this.http.post<string>(add_url, body, { headers: headers }).subscribe(
      (val) => {
        console.log('successfull', val);
      },
      (response) => {
        console.log('POST call in error', response);
      },
      () => {
        console.log('The POST observable is now completed.');
      }
    );
  }*/

  addTollGate(tollgateForm: any) {
    return this.http.post<TollGate>(
      this.configService.getConfig().END_POINT + "/api/tollgate/add", tollgateForm,
      { headers: this.localData.getHeaders() }
    );
  }

  addEntrySheet(entrySheetForm: any) {
    const headers = { "content-type": "application/json", "Authorization": `Bearer ${this.token}` };

    const add_url = this.configService.getConfig().END_POINT + "/api/addEntrySheet";

    console.log(headers);

    const body = JSON.stringify(entrySheetForm);
    console.log(body);

    this.http.post<string>(add_url, body, { headers: headers }).subscribe(
      (val) => {
        console.log("successfully", val);
      },
      (response) => {
        console.log("POST call in error", response);
      },
      () => {
        console.log("The POST observable is now completed.");
      }
    );
  }

  addProducts(productDetails: any) {
    const headers = { "content-type": "application/json", "Authorization": `Bearer ${this.token}` };
    const add_url = this.configService.getConfig().END_POINT + "/api/aftc-products/addProduct";
    const body = JSON.stringify(productDetails);
    console.log(add_url);
    this.http.post<string>(add_url, body, { headers: headers }).subscribe(
      (val) => {
        console.log("successfully", val);
      },
      (response) => {
        console.log("POST call in error", response);
      },
      () => {
        console.log("The POST observable is now completed.");
      }
    );
  }

  async addEmployee(employees: any): Promise<any> {
    const headers = { "content-type": "application/json" };
    const add_url = this.configService.getConfig().END_POINT + "/auth/aftc-employees/add";
    const body = JSON.stringify(employees);
    try {
      const t = await this.http
        .post(add_url, body, { headers: headers })
        .toPromise();
      return t;
    } catch (ex) {
      console.log(ex);
      return "";
    }
  }

  addRenter(renterDetails: any) {
    const headers = { "content-type": "application/json", "Authorization": `Bearer ${this.token}` };
    const add_url = this.configService.getConfig().END_POINT + "/api/aftc-renter/addRenter";
    const body = JSON.stringify(renterDetails);
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: "Bearer c3RyaW5nOnN0cmluZw=="
      })
    };
    this.http.post<string>(add_url, body, httpOptions).subscribe(
      (val) => {
        console.log("successfull", val);
      },
      (response) => {
        console.log("POST call in error", response);
      },
      () => {
        console.log("The POST observable is now completed.");
      }
    );
  }

  getDepartmentData(): any {
    const getDeptUrl = this.configService.getConfig().END_POINT + "/general/department/getAll/data";
    return this.http.get(getDeptUrl);
  }


  getEmpPosition(): any {
    const getEmpPositionUrl = this.configService.getConfig().END_POINT + "/general/employee/position";
    return this.http.get(getEmpPositionUrl);
  }

  getAllTollGate() {
    return this.http.get<TollGate[]>(
      this.configService.getConfig().END_POINT + "/api/tollgate/get",
      { headers: this.localData.getHeaders() }
    );
  }

  getAllTollGateByDate(date: string) {
    return this.http.get<TollGate[]>(
      this.configService.getConfig().END_POINT + "/api/tollgate/get/" + date,
      { headers: this.localData.getHeaders() }
    );
  }

  getTollGate(reportQr: string) {
    return this.http.get<TollGate>(
      this.configService.getConfig().END_POINT + "/api/tollgate/getTGDetailsByQr/" + reportQr,
      { headers: this.localData.getHeaders() }
    );
  }

  getTollGateByVehicleQr(vehicleQr: string) {
    return this.http.get<TollGate>(
      this.configService.getConfig().END_POINT + "/api/tollgate/getVehicleDetailsByQr/" + vehicleQr,
      // { headers: this.localData.getHeaders() }
    );
  }

  // Get tollgate details by seq id
  getTollGateById(tollgateSeqNo: number) {
    return this.http.get<TollGate>(
      this.configService.getConfig().END_POINT + "/api/tollgate/getTollgateDetailsById/" + tollgateSeqNo,
      { headers: this.localData.getHeaders() }
    );
  }

  //Get tollgate details by cirm number
  getTollgateDetailsByCirmNo(cirmNo: string) {
    return this.http.get<TollGate[]>(
      this.configService.getConfig().END_POINT + "/api/tollgate/getTollgateDetailsByCirmNo?cirmNo=" + cirmNo,
      { headers: this.localData.getHeaders() }
    );
  }

  // Get Member Details
  getAllMemberDetails() {
    return this.http.get<MemberDetails[]>(
      this.configService.getConfig().END_POINT + "/api/member/getAll",
      { headers: this.localData.getHeaders() }
    );
  }

  // Get Commodity Details
  getCommodityDetails() {
    return this.http.get<CommodityDetails[]>(
      this.configService.getConfig().END_POINT + "/api/commodity/getAll",
      { headers: this.localData.getHeaders() }
    );
  }

  //Save WarehouseStockDeposit Details

  addStockDeposit(depositForm: any) {
    return this.http.post<WarehouseStockDeposit>(
      this.configService.getConfig().END_POINT + "/api/aftc-stock-deposit/save", depositForm,
      { headers: this.localData.getHeaders() }
    );
  }

  //Get Deposit Details
  getDepositDetails() {
    return this.http.get<WarehouseStockDeposit[]>(
      this.configService.getConfig().END_POINT + "/api/aftc-stock-deposit/getAll",
      { headers: this.localData.getHeaders() }
    );
  }

  //Save StockDelivery Details

  addStockDelivery(deliveryForm: any) {
    return this.http.post<warehouseStockDelivery>(
      this.configService.getConfig().END_POINT + "/api/aftc-stock-delivery/save", deliveryForm,
      { headers: this.localData.getHeaders() }
    );
  }

  /*Get CW Deposit Application Number*/
  getDepositAppNo() {
    return this.http.get<ApplicationNumber>(
      this.configService.getConfig().END_POINT + "/api/aftc-stock-deposit/getAppNo",
      { headers: this.localData.getHeaders() }
    );
  }

  /*Get SLCM Deposit Application Number*/
  getSLCMDepositAppNo() {
    return this.http.get<ApplicationNumber>(
      this.configService.getConfig().END_POINT + "/api/aftc-stock-deposit/SLCM/getAppNo",
      { headers: this.localData.getHeaders() }
    );
  }

  /*Get Delivery Application Number*/
  getDeliveryAppNo() {
    return this.http.get<ApplicationNumber>(
      this.configService.getConfig().END_POINT + "/api/aftc-stock-delivery/getAppNo",
      { headers: this.localData.getHeaders() }
    );
  }

  /*Get SLCM Delivery Application Number*/
  getSLCMDeliveryAppNo() {
    return this.http.get<ApplicationNumber>(
      this.configService.getConfig().END_POINT + "/api/aftc-stock-delivery/SLCM/getAppNo",
      { headers: this.localData.getHeaders() }
    );
  }

  /*Get Solar Tunnel Dryer Deposit Application Number*/
  getSolarTunnelDryerDepositAppNo(){
    return this.http.get<ApplicationNumber>(
      this.configService.getConfig().END_POINT + "/api/aftc-stock-deposit/solar-tunnel/getAppNo",
      { headers: this.localData.getHeaders() }
    );
  }

  /*Lab Module*/

  /* Get Product */
  getProduct() {
    // @ts-ignore
    return this.http.get<ProcedureDetails[]>(
      this.configService.getConfig().END_POINT +
      "/api/getProcedureDetails", { headers: this.localData.getHeaders() }
    );
  }

  // Get All test details
  getAllTestDetails() {
    return this.http.get<AllTestDetails[]>(
      this.configService.getConfig().END_POINT +
      "/api/getAllTestDetails", { headers: this.localData.getHeaders() }
    );
  }

  /* Get NABL Table */
  getNABLTest() {
    return this.http.get<AllTestDetails[]>(
      this.configService.getConfig().END_POINT +
      "/api/getNABLTest", { headers: this.localData.getHeaders() }
    );
  }

  getNormalTest() {
    return this.http.get<AllTestDetails[]>(
      this.configService.getConfig().END_POINT +
      "/api/getNormalTest", { headers: this.localData.getHeaders() }
    );
  }

  getInternalTest() {
    return this.http.get<AllTestDetails[]>(
      this.configService.getConfig().END_POINT +
      "/api/getInternalTest", { headers: this.localData.getHeaders() }
    );
  }

  /*Save NABL Test Details*/
  saveNABLTestDetails(userForm: UntypedFormGroup) {
    return this.http.post<TestDetails[]>(
      this.configService.getConfig().END_POINT + "/api/NABLTestResults", userForm, { headers: this.localData.getHeaders() }
    );
  }

  /*Save  Normal Test Details*/
  saveNormalTestDetails(userForm: UntypedFormGroup) {
    return this.http.post<NormalTestDetails[]>(
      this.configService.getConfig().END_POINT + "/api/normalTestResults", userForm, { headers: this.localData.getHeaders() }
    );
  }

  /*Save Internal Test Details*/
  saveInternalTestDetails(userForm: UntypedFormGroup) {
    return this.http.post<InternalTestDetails[]>(
      this.configService.getConfig().END_POINT + "/api/internalTestResults", userForm, { headers: this.localData.getHeaders() }
    );
  }


  /*Update NABL Test Details*/
  updateNABLTestDetails(userForm: UntypedFormGroup) {
    return this.http.put<TestDetails[]>(
      this.configService.getConfig().END_POINT + "/api/NABLTestResults", userForm, { headers: this.localData.getHeaders() }
    );
  }

  /*Update  Normal Test Details*/
  updateNormalTestDetails(userForm: UntypedFormGroup) {
    return this.http.put<NormalTestDetails[]>(
      this.configService.getConfig().END_POINT + "/api/normalTestResults", userForm, { headers: this.localData.getHeaders() }
    );
  }

  /*Update Internal Test Details*/
  updateInternalTestDetails(userForm: UntypedFormGroup) {
    return this.http.put<InternalTestDetails[]>(
      this.configService.getConfig().END_POINT + "/api/internalTestResults", userForm, { headers: this.localData.getHeaders() }
    );
  }

  //Get getProcedureResults
  getProcedureResults(ulrNo: String) {
    return this.http.get<ProcedureResults[]>(
      this.configService.getConfig().END_POINT + "/aftc/getProcedure/" + ulrNo
    );
  }

  //Detete getProcedure
  getProcedure(resultID: Number) {
    return this.http.delete<ProcedureResults>(
      this.configService.getConfig().END_POINT + "/api/procedureDetails/" + resultID, { headers: this.localData.getHeaders() }
    );
  }

  UpdateActive(product_Id: number) {
    return this.http.put<ProcedureDetails>(
      this.configService.getConfig().END_POINT + "/api/updateActive/" + product_Id, "", { headers: this.localData.getHeaders() }
    );
  }

  UpdateDelete(product_Id: number) {
    return this.http.put<ProcedureDetails>(
      this.configService.getConfig().END_POINT + "/api/updateDelete/" + product_Id, "", { headers: this.localData.getHeaders() }
    );
  }

  //Get AllTestUrlNo

  getAllTestUrlNo() {
    return this.http.get<getUrlNo[]>(
      this.configService.getConfig().END_POINT + "/api/getAllTestUrlNo", { headers: this.localData.getHeaders() }
    );
  }

  //Get all NABL Test Details
  getTestDetails(ulrNo: String) {
    return this.http.get<TestDetails>(
      this.configService.getConfig().END_POINT + "/aftc/getTestResults/" + ulrNo
    );
  }

  viewTestDetails(ulrNo: String) {
    return this.http.get<TestDetails>(
      this.configService.getConfig().END_POINT + "/api/getTestResults/" + ulrNo
    );
  }


  //Get all NABL Test Details
  getNormalTestDetails(ulrNo: String) {
    return this.http.get<TestDetails>(
      this.configService.getConfig().END_POINT + "/aftc/getNormalTestResults/" + ulrNo
    );
  }

  //Get all NABL Test Details
  getInternalTestDetails(ulrNo: String) {
    return this.http.get<TestDetails>(
      this.configService.getConfig().END_POINT + "/aftc/getInternalTestResults/" + ulrNo
    );
  }

  //save Product
  saveProcedureDetails(userForm: UntypedFormGroup) {
    return this.http.post<ProcedureDetails[]>(
      this.configService.getConfig().END_POINT + "/api/procedureDetails", userForm, { headers: this.localData.getHeaders() }
    );
  }

  getCameraRecords() {
    return this.http.get<CameraRecords[]>(
      this.configService.getConfig().END_POINT + "/api/aftc-camera-records/getAll"
    );
  }

  getTechAnalystName() {
    return this.http.get<getTechnicalAnalystName[]>(
      this.configService.getConfig().END_POINT + "/api/getTechnicalAnalystName", { headers: this.localData.getHeaders() }
    );
  }

  /*Get Sample No Auto Generate*/
  getSampleNo() {
    return this.http.get<TestDetails[]>(
      this.configService.getConfig().END_POINT + "/api/getSampleNo", { headers: this.localData.getHeaders() }
    );
  }


  /*Get Normal Sample No Auto Generate*/
  getNormalSampleNo() {
    return this.http.get<NormalTestDetails[]>(
      this.configService.getConfig().END_POINT + "/api/getNormalSampleNo", { headers: this.localData.getHeaders() }
    );
  }


  /*Get Internal Sample No Auto Generate*/
  getInternalSampleNo() {
    return this.http.get<InternalTestDetails[]>(
      this.configService.getConfig().END_POINT + "/api/getInternalSampleNo", { headers: this.localData.getHeaders() }
    );
  }

  /*Stock Details Save Function */

  saveStcokDetilas(userForm: any) {
    return this.http.post<StockDetails>(
      this.configService.getConfig().END_POINT + "/api/stockUpload/save", userForm, { headers: this.localData.getHeaders() }
    );
  }

  /* Inpact Entry Details Get */
  getInpactDetails() {
    return this.http.get<StockDetails[]>(
      this.configService.getConfig().END_POINT + "/api/stockUpload/getAll", { headers: this.localData.getHeaders() }
    );
  }

  /* Depositor Details get */
  getDepositorDetails() {
    return this.http.get<DepositorDetails[]>(
      this.configService.getConfig().END_POINT + "/api/depositorDetails/getAll", { headers: this.localData.getHeaders() }
    );
  }

  /* Stack Number Details Get*/

  getStackNumber() {
    return this.http.get<StackNumberDetails[]>(
      this.configService.getConfig().END_POINT + "/api/stackNumber/getAll", { headers: this.localData.getHeaders() }
    );
  }

  // Add news feed content
  addNewsFeed(userForm: UntypedFormGroup) {
    return this.http.post<NewsFeed>(
      this.configService.getConfig().END_POINT + "/api/news_feed/add", userForm,
      { headers: this.localData.getHeaders() }
    );
  }

  // upload news feed file
  uploadNewsFeedFile(formData: FormData, feedId: number) {
    return this.http.post(this.configService.getConfig().END_POINT + "/api/news_feed/picture/upload?productId=" + feedId, formData,
      { headers: this.localData.getHeaders() }
    );
  }

  // Get all news feed
  getAllNewsfeed() {
    return this.http.get<NewsFeed[]>(
      this.configService.getConfig().END_POINT + "/api/news_feed/get",
      { headers: this.localData.getHeaders() }
    );
  }

  // Update Newsfeed status
  updateNewsFeedStatus(data: any) {
    return this.http.put<NewsFeed>(
      this.configService.getConfig().END_POINT + "/api/news_feed/update", data,
      { headers: this.localData.getHeaders() }
    );
  }

  /*Save Aftc Membership Details */

  saveMembership(formData: any) {
    return this.http.post<AftcMembershipDetails>(
      this.configService.getConfig().END_POINT + "/api/admin-dashboard/membership_details/save", formData
    );
  }

  /* Update Aftc Membership Details */
  updateMembership(formData: any) {
    return this.http.put<AftcMembershipDetails>(
      this.configService.getConfig().END_POINT + "/api/admin-dashboard/membership_details/update", formData
    );
  }

  /* Update Payment Status */

  updatePaymentStatus(userForm: any) {
    return this.http.put<AftcMembershipDetails>(
      this.configService.getConfig().END_POINT + "/api/admin-dashboard/membership_details/update_payment_status", userForm
    );
  }

  /*Save Aftc Test Membership Details */

  saveTestMembership(formData: any) {
    return this.http.post<AftcMembershipDetails>(
      this.configService.getConfig().END_POINT + "/api/admin-dashboard/membership_details_test/save", formData
    );
  }

  /* Update Aftc Test Membership Details */
  updateTestMembership(formData: any) {
    return this.http.put<AftcMembershipDetails>(
      this.configService.getConfig().END_POINT + "/api/admin-dashboard/membership_details_test/update", formData
    );
  }

  /* Update Test Payment Status */

  updateTestPaymentStatus(userForm: any) {
    return this.http.put<AftcMembershipDetails>(
      this.configService.getConfig().END_POINT + "/api/admin-dashboard/membership_details_test/update_payment_status", userForm
    );
  }

  // Get all applications
  getAllApplication() {
    return this.http.get<AftcMembershipDetails[]>(
      this.configService.getConfig().END_POINT + "/api/admin-dashboard/membership_details/getAll",
      { headers: this.localData.getHeaders() }
    );
  }

  fileGet(id: number, fileName: string) {
    return this.http.get(
      this.configService.getConfig().END_POINT +  "/api/admin-dashboard/membership_details/" + id + "?imageName=" + fileName,
      { headers: this.localData.getHeaders(), responseType: 'blob' }
    )
  }

  updateApprove(userForm: any) {
    return this.http.put<AftcMembershipDetails>(
      this.configService.getConfig().END_POINT + "/api/admin-dashboard/membership_details/update_status", userForm,
      { headers: this.localData.getHeaders() }
    );
  }

  sendApproveMail(memberId: number) {
    return this.http.post<AftcMembershipDetails>(
      this.configService.getConfig().END_POINT + "/api/admin-dashboard/membership_details/sendUpdateStatus/" + memberId, null,
      { headers: this.localData.getHeaders() }
    );
  }

  sendWhatsapp(memberId: number, isSend: Boolean,paymntType : String) {
    return this.http.post<AftcMembershipDetails>(
      this.configService.getConfig().END_POINT + "/api/admin-dashboard/membership_details/sendWhatsappMassage/" + memberId + "/" + isSend
      + "/" + paymntType, null,
      { headers: this.localData.getHeaders() }
    );
  }

  //ID based Member Details Get

  getMemberDetailsid(memberId: number) {
    return this.http.get<AftcMembershipDetails>(
      this.configService.getConfig().END_POINT + "/api/admin-dashboard/membership_details/getById/" + memberId
    );
  }

  //Get All Membership Details
  getAllMembershipDetails() {
    return this.http.get<AftcMembershipDetails[]>(
      this.configService.getConfig().END_POINT + "/api/admin-dashboard/membership_details/getAll"
    );
  }

//Save Depot Details
  saveDepotDetails(userForm: any) {
    return this.http.post<depotDetails[]>(
      this.configService.getConfig().END_POINT + "/api/aftc-deopot-details/save", userForm,
      { headers: this.localData.getHeaders() }
    );
  }

//Get Depot Details

  getDepotDetails() {
    return this.http.get<depotDetails[]>(
      this.configService.getConfig().END_POINT + "/api/aftc-deopot-details/getAll",
      { headers: this.localData.getHeaders() }
    );
  }

//Save Admin Vehicle Details

  saveAdminVehicle(userForm: any) {
    return this.http.post<AdminVehicleDetails>(
      this.configService.getConfig().END_POINT + "/api/vehicle/admin/add", userForm,
      { headers: this.localData.getHeaders() }
    );
  }

//Get Admin Vehicle Details

  getAdminVehicleDetails() {
    return this.http.get<AdminVehicleDetails[]>(
      this.configService.getConfig().END_POINT + "/api/vehicle/admin/getAll",
      { headers: this.localData.getHeaders() }
    );
  }

//Save Depot Vehicle Details

  saveDepotVehicle(userForm: any) {
    return this.http.post<DepotVehicleDetails>(
      this.configService.getConfig().END_POINT + "/api/vehicle/depot/add", userForm,
      { headers: this.localData.getHeaders() }
    );
  }

//Get Depot Vehicle Details

  getDepotVehicleDetails() {
    return this.http.get<DepotVehicleDetails[]>(
      this.configService.getConfig().END_POINT + "/api/vehicle/depot/getAll",
      { headers: this.localData.getHeaders() }
    );
  }


  saveAllVehicleDetails(userForm: any) {
    return this.http.post<AllVehicleRegDetails>(
      this.configService.getConfig().END_POINT + "/api/vehicle-entry/register", userForm,
      { headers: this.localData.getHeaders() }
    );
  }

  getAllVehicleDetails() {
    return this.http.get<AllVehicleRegDetails[]>(
      this.configService.getConfig().END_POINT + "/api/vehicle-entry/getAll",
      { headers: this.localData.getHeaders() }
    );
  }


  getVehiclPunchDetails() {
    return this.http.get<vehiclePunchDetails[]>(
      this.configService.getConfig().END_POINT + "/api/vehicle-punch/getAll",
      { headers: this.localData.getHeaders() }
    );
  }

  getDateBasedVehiclePunchDetails(createdAt: any) {
    return this.http.get<vehiclePunchDetails[]>(
      this.configService.getConfig().END_POINT + "/api/vehicle-punch/findByDate/" + createdAt,
      { headers: this.localData.getHeaders() }
    );
  }

  getDeliveryDetails(deliveryId: any) {
    return this.http.get<warehouseStockDelivery>(
      this.configService.getConfig().END_POINT + "/api/aftc-stock-delivery/getById/" + deliveryId,
      { headers: this.localData.getHeaders() }
    );
  }

  printQR(vehicleQR: any) {
    return this.http.post<VehicleQRPrintData>(
      "http://192.168.1.107:9100/printer-ws/api/print/vehicleQr", vehicleQR
    );
  }

  // Deposit details get by id
  getDepositDetailsById(depositorId: number) {
    return this.http.get<WarehouseStockDeposit>(
      this.configService.getConfig().END_POINT + "/api/aftc-stock-deposit/getById/" + depositorId,
      { headers: this.localData.getHeaders() }
    );
  }


  // Update Front Offfice Cash Status

  updateCashStatus(cashDepositList: any[]) {
    return this.http.put<TollGate[]>(
      this.configService.getConfig().END_POINT + "/api/tollgate/updateCashDeposit", cashDepositList,
      { headers: this.localData.getHeaders() }
    );
  }

  //Get TollGate Cash Details
  getTollGateCash() {
    return this.http.get<TollGateCashDetails[]>(
      this.configService.getConfig().END_POINT + "/api/tollgate-cash/getAll",
      { headers: this.localData.getHeaders() }
    );
  }


  //Update TollGate Cash Recived Status

  updateTollGateCashStatus(fromData: any) {
    return this.http.put(
      this.configService.getConfig().END_POINT + "/api/tollgate-cash/update", fromData,
      { headers: this.localData.getHeaders() }
    );
  }

  // Get Preprocessing Application No
  getPreprocessingDepositAppNo() {
    return this.http.get<ApplicationNumber>(
      this.configService.getConfig().END_POINT + "/api/aftc-stock-deposit/preprocessing/getAppNo",
      { headers: this.localData.getHeaders() }
    );
  }

  // Save Preprocessing deposit
  addPreprocessingStockDeposit(depositForm: any) {
    return this.http.post<PreprocessingDepositDetails>(
      this.configService.getConfig().END_POINT + "/api/aftc-stock-deposit/preprocessing/save", depositForm,
      { headers: this.localData.getHeaders() }
    );
  }

  // Get Preprocessing deposit details by id
  getPreprocessingDepositDetailsById(depositorId: number) {
    return this.http.get<PreprocessingDepositDetails>(
      this.configService.getConfig().END_POINT + "/api/aftc-stock-deposit/preprocessing/getById/" + depositorId,
      { headers: this.localData.getHeaders() }
    );
  }

  // Get Cold Storage Application No
  getColdStorageDepositAppNo() {
    return this.http.get<ApplicationNumber>(
      this.configService.getConfig().END_POINT + "/api/aftc-stock-deposit/coldStorage/getAppNo",
      { headers: this.localData.getHeaders() }
    );
  }

  // Save Cold Storage deposit
  addColdStorageStockDeposit(depositForm: any) {
    return this.http.post<ColdStorageDepositDetails>(
      this.configService.getConfig().END_POINT + "/api/aftc-stock-deposit/coldStorage/save", depositForm,
      { headers: this.localData.getHeaders() }
    );
  }

  // Get Cold Storage deposit details by id
  getColdStorageDepositDetailsById(depositorId: number) {
    return this.http.get<ColdStorageDepositDetails>(
      this.configService.getConfig().END_POINT + "/api/aftc-stock-deposit/coldStorage/getById/" + depositorId,
      { headers: this.localData.getHeaders() }
    );
  }

  // Get Cold Storage Delivery No
  getColdStorageDeliveryAppNo() {
    return this.http.get<ApplicationNumber>(
      this.configService.getConfig().END_POINT + "/api/aftc-stock-delivery/coldStorage/getAppNo",
      { headers: this.localData.getHeaders() }
    );
  }

  // Save Cold Storage Delivery Details
  addColdStorageStockDelivery(deliveryForm: any) {
    return this.http.post<ColdStorageDeliveryDetails>(
      this.configService.getConfig().END_POINT + "/api/aftc-stock-delivery/coldStorage/save", deliveryForm,
      { headers: this.localData.getHeaders() }
    );
  }

  // Get Cold Storage Details By Id
  getColdStorageDeliveryDetailsById(deliveryId: any) {
    return this.http.get<ColdStorageDeliveryDetails>(
      this.configService.getConfig().END_POINT + "/api/aftc-stock-delivery/coldStorage/getById/" + deliveryId,
      { headers: this.localData.getHeaders() }
    );
  }

  // Get Packing Deposit Application No
  getPackingDepositAppNo() {
    return this.http.get<ApplicationNumber>(
      this.configService.getConfig().END_POINT + "/api/aftc-stock-deposit/packing/getAppNo",
      { headers: this.localData.getHeaders() }
    );
  }

  // Save Packing deposit
  addPackingStockDeposit(depositForm: any) {
    return this.http.post<PackingDepositDetails>(
      this.configService.getConfig().END_POINT + "/api/aftc-stock-deposit/packing/save", depositForm,
      { headers: this.localData.getHeaders() }
    );
  }

  // Get Packing deposit details by id
  getPackingDepositDetailsById(depositorId: number) {
    return this.http.get<PackingDepositDetails>(
      this.configService.getConfig().END_POINT + "/api/aftc-stock-deposit/packing/getById/" + depositorId,
      { headers: this.localData.getHeaders() }
    );
  }


  //Save Solar Tunnel Dryer deposit
  addSolarTunnelDryerStockDeposit(depositForm: any) {
    return this.http.post<SolarTunnelDryerDepositDetails>(
      this.configService.getConfig().END_POINT + "/api/aftc-stock-deposit/solar-tunnel/save", depositForm,
      { headers: this.localData.getHeaders() }
    );
  }

  // Get Solar Tunnel Dryer deposit details by id
  getSolarTunnelDryerDepositDetailsById(depositorId: number) {
    return this.http.get<SolarTunnelDryerDepositDetails>(
      this.configService.getConfig().END_POINT + "/api/aftc-stock-deposit/solar-tunnel/getById/" + depositorId,
      { headers: this.localData.getHeaders() }
    );
  }

  // Warehouse stock deposit get all
  getAllWarehouseDepositDetails() {
    return this.http.get<WarehouseStockDeposit[]>(
      this.configService.getConfig().END_POINT + "/api/aftc-stock-deposit/getAll",
      { headers: this.localData.getHeaders() }
    );
  }

  // Warehouse stock delivery get all
  getAllWarehouseDeliveryDetails() {
    return this.http.get<warehouseStockDelivery[]>(
      this.configService.getConfig().END_POINT + "/api/aftc-stock-delivery/getAll",
      { headers: this.localData.getHeaders() }
    );
  }

  // Save weigh bridge details
  saveWeighBridge(formValue: any) {
    return this.http.post<WeighBridgeDetails>(
      this.configService.getConfig().END_POINT + "/api/weighBridge/add", formValue,
      { headers: this.localData.getHeaders() }
    );
  }

  //Update weigh bridge details
  updateWeighBridge(formValue: any) {
    return this.http.put<WeighBridgeDetails>(
      this.configService.getConfig().END_POINT + "/api/weighBridge/update", formValue,
      { headers: this.localData.getHeaders() }
    );
  }

  // Get Weigh bridge details by id
  getWeighBridgeDetailsById(weighBridgeId: number) {
    return this.http.get<WeighBridgeDetails>(
      this.configService.getConfig().END_POINT + "/api/weighBridge/getById/" + weighBridgeId,
      { headers: this.localData.getHeaders() }
    );
  }

  // Get all weigh bridge details
  getAllWeighBridgeDetails() {
    return this.http.get<WeighBridgeDetails[]>(
      this.configService.getConfig().END_POINT + "/api/weighBridge/getAll",
      { headers: this.localData.getHeaders() }
    );
  }

  //Get Weigh Bridge Transaction No
  getTransactionNo() {
    return this.http.get<TransactionNumber>(
      this.configService.getConfig().END_POINT + "/api/weighBridge/getTransactionNo",
      { headers: this.localData.getHeaders() }
    );
  }

  // Update cash deposit
  updateCashDepositStatus(weighBridgeIdList: number[]) {
    return this.http.put<WeighBridgeDetails[]>(
      this.configService.getConfig().END_POINT + "/api/weigh-bridge/updateCashDeposit", weighBridgeIdList,
      { headers: this.localData.getHeaders() }
    );
  }

  // Get all weigh bridge cash details
  getAllWeighBridgeCash() {
    return this.http.get<WeighBridgeCashDetails[]>(
      this.configService.getConfig().END_POINT + "/api/weigh-bridge-cash/getAll",
      { headers: this.localData.getHeaders() }
    );
  }

  // Update weigh bridge cash received status
  updateWeighBridgeCashReceivedStatus(fromData: any) {
    return this.http.put(
      this.configService.getConfig().END_POINT + "/api/weigh-bridge-cash/update", fromData,
      { headers: this.localData.getHeaders() }
    );
  }

  //Get weigh bridge details by wbCashSeq
  getWeighBridgeDetailsByWnCashSeq(wbCashSeq: string) {
    return this.http.get<WeighBridgeDetails[]>(
      this.configService.getConfig().END_POINT + "/getWeighBridgeDetailsByWbCashSeq?wbCashSeq=" + wbCashSeq,
      {headers: this.localData.getHeaders()}
    )
  }

  //Get toll gate entry date list
  getTollgateEntryDateList(){
    return this.http.get<any[]>(
      this.configService.getConfig().END_POINT + "/api/tollgate/getDataByTollgateDate",
      {headers: this.localData.getHeaders()}
    )
  }

  //Delete for tollgate entry details
  deleteTollgateEntryDetails(jsonData: any){
    return this.http.put<TollGate>(
      this.configService.getConfig().END_POINT + "/api/tollgate/deleteTollEntry", jsonData ,
      {headers: this.localData.getHeaders()}
    )
  }
}
