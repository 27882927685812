import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ApiService } from "@services/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Subject, takeUntil } from "rxjs";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";


@Component({
  selector: "add-new-members-entry-form",
  templateUrl: "./add-new-members-component.html",
  styleUrls: ["./add-new-members-component.scss"]
})
export class AddNewMembersDetailsComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  dropdownList = [];
  options: any[] = [];
  depotNumberList = [];
  slDepotNumberList = [];
  selectedItems = this.dropdownList;
  selectedItemsDepotNumber = this.depotNumberList;
  selectedItemsSLDepotNumber = this.slDepotNumberList;
  dropdownSettings = {};
  selectedArrayType: any[] = [];
  selectedLabel: any[] = [];
  selectDepotNumbers: any[] = [];
  selectSLDepotNumbers: any[] = [];
  constructor(private apiServices: ApiService, private spinner: NgxSpinnerService,
    private router: Router, private toaster: ToastrService) {
    this.dropdownSettings = {
      singleSelection: false,
      idField: "id",
      textField: "label",
      selectAllText: "Select All",
      unSelectAllText: "Unselect All",
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  depositDetailsForm = new UntypedFormGroup({
    depotDetailsId: new UntypedFormControl(0, [Validators.required, Validators.nullValidator]),
    testDepotNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    depotNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    depotOccupantName: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    aftcMembershipType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    testServiceType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    serviceType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    testSlDepotNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    slDepotNo: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    records: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    createdAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    updatedAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator])
  });

  depositFormErrors = {
    depotOccupantName: "",
    // testDepotNo: "",
    // testSlDepotNo: "",
    aftcMembershipType: "",
    testServiceType: "",
  };

  ngOnInit() {
    this.depotNumberList = [];
    this.slDepotNumberList = [];
    this.dropdownList = [
      { id: 1, label: "Depots" },
      { id: 2, label: "Common Warehouse" },
      { id: 3, label: "Cold Storage Unit" },
      { id: 4, label: "Packaging Unit" },
      { id: 5, label: "Cleaning & Grading" },
      { id: 6, label: "R & D" },
      { id: 7, label: "Solar Tunnel Dryer" }
    ];
    for (let i = 1; i <= 256; i++) {
      this.depotNumberList.push({ id: i, label: `D:${i}` });
    }

    for (let i = 1; i <= 256; i++) {
      this.slDepotNumberList.push({ id: i, label: ` SL D:${i}` });
    }

  }


  formValidationDepot() {
    this.depositFormErrors.depotOccupantName = "";
    // this.depositFormErrors.testDepotNo = "";
    // this.depositFormErrors.testSlDepotNo = "";
    this.depositFormErrors.aftcMembershipType = "";
    this.depositFormErrors.testServiceType = "";
    let hasError = false;

    if (this.depositDetailsForm.get('depotOccupantName')?.invalid) {
      this.depositFormErrors.depotOccupantName = "* Depositor Name is required";
      this.depositDetailsForm.markAsTouched();
      hasError = true;
    }

    // if (this.depositDetailsForm.get('testDepotNo')?.invalid && this.depositDetailsForm.get('testSlDepotNo')?.invalid) {
    //   this.depositFormErrors.testDepotNo = "* Depot No is required";
    //   this.depositFormErrors.testSlDepotNo = "* SL Depot No is required";
    //   this.depositDetailsForm.markAsTouched();
    //   hasError = true;
    // } else {
    //   this.depositFormErrors.testDepotNo = "";
    //   this.depositFormErrors.testSlDepotNo = "";
    // }



    if (this.depositDetailsForm.get("aftcMembershipType").invalid) {
      this.depositFormErrors.aftcMembershipType = "* Membership Type is required";
      this.depositDetailsForm.markAsTouched();
      hasError = true;
    }
    if (this.depositDetailsForm.get('testServiceType')?.invalid) {
      this.depositFormErrors.testServiceType = "* Service Type is required";
      this.depositDetailsForm.markAsTouched();
      hasError = true;
    }

    if (!hasError) {
      this.saveDepotDetails();
    }
  }

  initial_data() {
    const now = new Date();
    const localDateTime = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
    const formattedDateTime = localDateTime.toISOString().slice(0, 16);
  }

  onItemDeSelect(item: any) {
    const index = this.selectedArrayType.findIndex((selectedItem: any) => selectedItem.id === item.id);
    if (index !== -1) {
      this.selectedArrayType.splice(index, 1);
    }
  }

  onItemSelect(items: any) {
    this.selectedArrayType.push(items);
  }

  onSelectAll(items: any) {
    this.selectedArrayType = [];
    this.selectedArrayType = items;
  }

  onDeSelectAll(items: any) {
    this.selectedArrayType = [];
  }

  selectedServiceType(event: any) {
    const value = event.target.value;
    this.selectedArrayType = value;
  }

  onItemDeSelectDepotNumber(item: any) {
    const index = this.selectDepotNumbers.findIndex((selectedItemsDepotNumber: any) => selectedItemsDepotNumber.id === item.id);
    if (index !== -1) {
      this.selectDepotNumbers.splice(index, 1);
    }
  }

  onItemSelectDepotNumber(items: any) {
    this.selectDepotNumbers.push(items);
  }

  onSelectAllDepotNumber(items: any) {
    this.selectDepotNumbers = [];
    this.selectDepotNumbers = items;
  }

  selectedDepotNumber(event: any) {
    const value = event.target.value;
    this.selectDepotNumbers = value;
  }

  onDeSelectDepotNumberAll(item: any) {
    this.selectDepotNumbers = [];
  }

  onItemDeSelectSLDepotNumber(item: any) {
    const index = this.selectSLDepotNumbers.findIndex((selectedItemsSLDepotNumber: any) => selectedItemsSLDepotNumber.id === item.id);
    if (index !== -1) {
      this.selectSLDepotNumbers.splice(index, 1);
    }
  }

  onItemSelectSLDepotNumber(items: any) {
    this.selectSLDepotNumbers.push(items);
  }

  onSelectAllSLDepotNumber(items: any) {
    this.selectSLDepotNumbers = [];
    this.selectSLDepotNumbers = items;
  }

  onDeSelectSLDepotNumberAll(items: any) {
    this.selectSLDepotNumbers = [];
  }

  selectedSLDepotNumber(event: any) {
    const value = event.target.value;
    this.selectSLDepotNumbers = value;
  }

  saveDepotDetails() {
    if (this.selectDepotNumbers.length > 0) {
      const depotNumbers = this.selectDepotNumbers.map(item => item.label.split(':')[1]).join(',');
      const result = `D:${depotNumbers}`;
      this.depositDetailsForm.controls['depotNo'].setValue(result);
    } else {
      this.depositDetailsForm.controls['depotNo'].setValue('');
    }

    if (this.selectSLDepotNumbers.length > 0) {
      const slDepotNumbers = this.selectSLDepotNumbers.map(item => item.label.split(':')[1]).join(',');
      const result = `D:${slDepotNumbers}`;
      this.depositDetailsForm.controls['slDepotNo'].setValue(result);
    } else {
      this.depositDetailsForm.controls['slDepotNo'].setValue('');
    }
    this.depositDetailsForm.controls['records'].setValue('NEW');
    const result = this.selectedArrayType.map(item => item.label).join(', ');
    this.depositDetailsForm.controls['serviceType'].setValue(result);
    this.spinner.show();
    console.log("Save Depot Details", this.depositDetailsForm.value);
    this.apiServices.saveDepotDetails([this.depositDetailsForm.value])
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.spinner.hide();
        this.saveAlert();
      }, (err: any) => {
        this.spinner.hide();
        this.toaster.error("Something went wrong, Please try again later");
      });

  }


  saveAlert() {
    Swal.fire({
      title: "Details Saved Successfully...",
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      showClass: {
        popup: "animate__animated animate__fadeInDown"
      },
      hideClass: {
        popup: "animate__animated animate__fadeOutUp"
      }
    }).then((result) => {
      window.location.reload();
    });
  }


}
