<nav class="navbar navbar-expand-lg navbar-light" style="background-color:rgb(81,201,99)">
  <div class="container-fluid">
    <p class="navbar-brand fw-bold" href="#">AFTC Members Details</p>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
      aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav" type="button">
      <ul class="navbar-nav">
        <li class="nav-item">
          <p class="nav-link" routerLink="/main/add-new-members" href="#" [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{exact:true}">AFTC Members Entry Form</p>
        </li>
        <li class="nav-item">
          <p class="nav-link" routerLink="/main/depotView" href="#" [routerLinkActive]="['active']"
            [routerLinkActiveOptions]="{exact:true}">AFTC Members Data View</p>
        </li>
      </ul>
    </div>
  </div>
</nav>
<section class="content px-2 py-3">
  <form [formGroup]="depositDetailsForm">
    <div class="row py-4"></div>
    <h3 class="text-left">Depot Registration</h3>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-12">
        <div class="form-floating mt-3">
          <input type="text" class="form-control" placeholder="Member Name" formControlName="depotOccupantName"
            [ngClass]="{'is-invalid': depositDetailsForm.get('depotOccupantName')?.invalid && depositDetailsForm.get('depotOccupantName')?.touched,
                    'is-valid': depositDetailsForm.get('depotOccupantName')?.valid && depositDetailsForm.get('depotOccupantName')?.touched}">
          <label>Member Name</label>
        </div>
        <span *ngIf="depositFormErrors.depotOccupantName"
          class="text-danger">{{depositFormErrors.depotOccupantName}}</span>
      </div>

      <div class="col-lg-4 col-md-6 col-12">
        <div class="form-floating mt-3">
          <div class="custom-dropdown-wrapper">
            <ng-multiselect-dropdown [placeholder]="'Select Depot Number'" [settings]="dropdownSettings"
              [data]="depotNumberList" [(ngModel)]="selectedItemsDepotNumber"
              (onSelect)="onItemSelectDepotNumber($event)" (onSelectAll)="onSelectAllDepotNumber($event)"
              (change)="selectedDepotNumber($event)" (onDeSelect)="onItemDeSelectDepotNumber($event)"
              (onDeSelectAll)="onDeSelectDepotNumberAll($event)" class="form-control" formControlName="testDepotNo">
            </ng-multiselect-dropdown>
          </div>
          <!-- <label>Depot Number</label> -->
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-12">
        <div class="form-floating mt-3">
          <div class="custom-dropdown-wrapper">
            <ng-multiselect-dropdown [placeholder]="'Select SL Depot Number'" [settings]="dropdownSettings"
              [data]="slDepotNumberList" [(ngModel)]="selectedItemsSLDepotNumber"
              (onSelect)="onItemSelectSLDepotNumber($event)" (onSelectAll)="onSelectAllSLDepotNumber($event)"
              (change)="selectedSLDepotNumber($event)" (onDeSelect)="onItemDeSelectSLDepotNumber($event)"
              (onDeSelectAll)="onDeSelectSLDepotNumberAll($event)" class="form-control" formControlName="testSlDepotNo">
            </ng-multiselect-dropdown>
          </div>
          <!-- <label>Depot Number</label> -->
        </div>
      </div>

      <div class="col-lg-4 col-md-6 col-12">
        <div class="form-floating mt-3">
          <select class="form-select" aria-label="Default select example" formControlName="aftcMembershipType"
            placeholder="Aftc Memember Type"
            [ngClass]="{'is-invalid': depositDetailsForm.get('aftcMembershipType')?.invalid && depositDetailsForm.get('aftcMembershipType')?.touched,
                    'is-valid': depositDetailsForm.get('aftcMembershipType')?.valid && depositDetailsForm.get('aftcMembershipType')?.touched}">
            <option selected disabled value="">Select a AFTC Memember Type</option>
            <option value="SH">Stack Holder (SH)</option>
            <option value="UM">Utility Member (UM)</option>
          </select>
          <label>AFTC Memember Type</label>
        </div>
        <span *ngIf="depositFormErrors.aftcMembershipType"
          class="text-danger">{{depositFormErrors.aftcMembershipType}}</span>
      </div>

      <div class="col-lg-4 col-md-6 col-12 mt-3">
        <div class="form-floating">
          <div class="custom-dropdown-wrapper">
            <ng-multiselect-dropdown [placeholder]="'Select Service Type'" [settings]="dropdownSettings"
              [data]="dropdownList" [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)"
              (onSelectAll)="onSelectAll($event)" (change)="selectedServiceType($event)"
              (onDeSelect)="onItemDeSelect($event)" (onDeSelectAll)="onDeSelectAll($event)" class="form-control"
              formControlName="testServiceType"
              [ngClass]="{'is-invalid': depositDetailsForm.get('testServiceType')?.invalid && depositDetailsForm.get('testServiceType')?.touched,
                    'is-valid': depositDetailsForm.get('testServiceType')?.valid && depositDetailsForm.get('testServiceType')?.touched}">
            </ng-multiselect-dropdown>
          </div>
        </div>

        <span *ngIf="depositFormErrors.testServiceType" class="text-danger">
          {{ depositFormErrors.testServiceType }}
        </span>
      </div>



      <div class="text-center py-3"></div>
      <div class="text-center">
        <button type="button" class="btn btn-success" (click)="formValidationDepot()">Save</button>
      </div>
      <div class="text-center py-3"></div>
    </div>
  </form>
</section>

<ngx-spinner>
  <div class="loaderssm">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
  </div>
</ngx-spinner>